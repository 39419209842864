import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import {Drawer, MenuItem, Tab, Tabs, Tooltip} from "@mui/material";
import EmployeeSummary from "../../components/Pages/RunPayroll/EmployeeSummary";
import {useNavigate, useParams} from "react-router-dom";
import {
	bulkImportPayItems,
	confirmPayRoll,
	runPayRoll,
	saveHoursWorked,
	sendPayslip,
	viewPaymentBreakdown,
	viewPayslip
} from "../../actions/PayScheduleRun";
import ReviewPayroll from "../../components/Pages/RunPayroll/ReviewPayroll";
import {findEmployer} from "../../actions/Employer";
import {ReactSpreadsheetImport} from "react-spreadsheet-import";
import Menu from "@mui/material/Menu";
import {formatDateFromBackendWithTime} from "../../utils/Helpers";
import EmployeesTable from "../../components/Pages/RunPayroll/EmployeesTable";
import StyleButton from "../../components/StyledComponents/StyleButton";
import Request from "../../utils/Request";

const fieldsForHours = [
	{
		label: "Payroll ID",
		key: "payroll_id",
		alternateMatches: ["payroll id", "Payroll ID"],
		fieldType: {
			type: "input",
		},
		example: "EC1",
		validations: [
			{
				rule: "required",
				errorMessage: "Payroll ID is required",
				level: "error",
			},
			{
				rule: "unique",
				errorMessage: "Payroll ID needs to be unique",
				level: "error",
			},
		],
	},
	{
		label: "Hours Worked",
		key: "hours_worked",
		alternateMatches: ["hours worked", "Hours Worked"],
		fieldType: {
			type: "input",
		},
		example: "30.3",
		validations: [
			{
				rule: "regex",
				value: /^-?\d+(\.\d+)?$/,
				errorMessage: "Hours Worked must be a number",
				level: "error",
			},
		],
	},
];

const fieldsPayItem = [
	{
		label: "Payroll ID",
		key: "payroll_id",
		alternateMatches: ["payroll id", "Payroll ID"],
		fieldType: {
			type: "input",
		},
		example: "EC1",
		validations: [
			{
				rule: "required",
				errorMessage: "Payroll ID is required",
				level: "error",
			},
		],
	},
	{
		label: "Item Name",
		key: "item_name",
		alternateMatches: ["item name", "Item name"],
		fieldType: {
			type: "input",
		},
		example: "Director bonus",
		validations: [
			{
				rule: "required",
				errorMessage: "Item name is required",
				level: "error",
			},
		],
	},
	{
		label: "Is Custom Salary",
		key: "is_salary_rate",
		alternateMatches: ["is custom salary rate", "Is Custom Salary"],
		fieldType: {
			type: "input",
		},
		example: "Yes or No",
		validations: [
			{
				rule: "regex",
				value: /^(Yes|No|yes|no)$/,
				errorMessage: "Is Custom Salary Rate must be Yes or No",
			}
		],
	},
	{
		label: "Amount",
		key: "total_amount",
		alternateMatches: ["amount", "Amount"],
		fieldType: {
			type: "input",
		},
		example: "2000.53",
		validations: [
			{
				rule: "regex",
				errorMessage: "Total amount is required. It must be a number between 0 and 300000. For decimals, we only accept up to two decimal places, and you muse a dot.",
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
			},
		],
	},
	{
		label: "Hours/Units",
		key: "hours",
		alternateMatches: ["hours", "hours/units", "Hours/Units"],
		fieldType: {
			type: "input",
		},
		example: "30 or 27.5",
		validations: [
			{
				rule: "regex",
				value: /^(?:300(?:\.0{1,2})?|[1-2]?\d?\d(?:\.\d{1,2})?)?$|^$/,
				errorMessage: "Hours must be a number between 0 and 300. For decimals, we only accept up to two decimal places, and you muse a dot.",
			},
		],
	},
];

function fillHoursOrPayItem(
	updateEmployeePayRuns = [],
	csvData = [],
	setUpdateEmployeePayRuns = () => {},
	isHours = false,
	isPayItem = false,
) {
	const updatedPayRuns = updateEmployeePayRuns.map(item => {
		const matchingObject = csvData.find(obj => obj?.payroll_id === String(item?.employee?.payroll_id));
		if (matchingObject) {
			if (isHours) {
				return {
					...item,
					work_hours: matchingObject?.hours_worked
				};
			} else if (isPayItem) {
				return {
					...item,
					pay_items: [...(item.pay_items || []), matchingObject?.pay_item]
				};
			} else {
				return item;
			}
		} else {
			return item;
		}
	});
	setUpdateEmployeePayRuns(updatedPayRuns);
}

function renderPayrollStepContent (
	tabIndex,
	setTabIndex,
	id,
	updateEmployeePayRuns,
	setUpdateEmployeePayRuns,
	employee_pay_runs,
	runPayRoll,
	openMenu,
	setOpenMenu,
	anchorEl,
	setAnchorEl,
	setOpenImportFlow,
	setSelectedEmployee,
	showSummary,
	setShowSummary,
	payroll_summary,
	confirmPayRoll,
	pay_schedule_run,
	push,
	viewPaymentBreakdown,
	payments_breakdown,
	saveHoursWorked,
	validationData,
	setValidationData
) {
	if (tabIndex < 3) {
		return (
			<>
				{/*Render Buttons*/}
				<Grid
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					item
					container
					justifyContent={"space-between"}
					alignItems={"center"}
					sx={{
						marginTop: "40px",
					}}
				>
					{/*Import Button*/}
					<Grid
						xs={6}
						item
						container
					>
						<Grid
							xs={3.5}
							item
						>
							<StyleButton
								isWhiteButton={true}
								onClick={(e) => {
									setShowSummary(false);
									setOpenMenu(true);
									setAnchorEl(e.currentTarget)
								}}
							>
								<StyleTypography
									fontSize={16}
									fontSizeMedium={12}
									color={"#000"}
								>
									Import
								</StyleTypography>
							</StyleButton>
						</Grid>
						<Menu
							open={openMenu}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							onClose={() => {
								setOpenMenu(false);
								setAnchorEl(null);
							}}
						>
							<MenuItem
								onClick={() => {
									setOpenMenu(false);
									setAnchorEl(null);
									setOpenImportFlow(true);
									Request.get(`/api/employers/validate-import-data${tabIndex === 0 ? "?mode=salaried" : "?mode=hourly"}`)
										.then(response => {
											setValidationData(response?.data?.data);
										})
								}}
								disabled={tabIndex !== 0}
							>
								Import pay items for salaried employees
							</MenuItem>
							<MenuItem
								onClick={() => {
									setOpenMenu(false);
									setAnchorEl(null);
									setOpenImportFlow(true);
								}}
								disabled={tabIndex !== 1}
							>
								Import hours for hourly employees
							</MenuItem>
							<MenuItem
								onClick={() => {
									setOpenMenu(false);
									setAnchorEl(null);
									setOpenImportFlow(true);
									Request.get(`/api/employers/validate-import-data${tabIndex === 0 ? "?mode=salaried" : "?mode=hourly"}`)
										.then(response => {
											setValidationData(response?.data?.data);
										})
								}}
								disabled={tabIndex !== 2}
							>
								Import pay items for hourly employees
							</MenuItem>
						</Menu>
					</Grid>

					{/*Next Button and Save Hours*/}
					<Grid
						xs={6}
						item
						container
						justifyContent={"flex-end"}
					>
						<Grid
							marginRight={2}
							xs={3.5}
							item
							display={tabIndex === 1 ? "block" : "none"}
						>
							<StyleButton
								onClick={() => {
									saveHoursWorked({
										"pay_schedule_runs": {
											id: id,
											pay_runs: updateEmployeePayRuns.map((item) => {
												return {
													id: item.id,
													work_hours: item.work_hours ?? 0,
													hourly_rate: item.hourly_rate,
												};
											})
										}
									});
								}}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									fontWeight={"bold"}
									color={"#FFFFFF"}
								>
									Save Hours
								</StyleTypography>
							</StyleButton>
						</Grid>
						<Grid
							xs={3.5}
							item
						>
							<Tooltip
								arrow={true}
								title="Button is disabled, you need to save hours before proceeding."
								open={updateEmployeePayRuns !== employee_pay_runs}
							>
                                <span>
									<StyleButton
										isBlueButton={true}
										disabled={updateEmployeePayRuns !== employee_pay_runs}
										onClick={() => {
											setTabIndex(tabIndex + 1);
											setSelectedEmployee([]);
											setShowSummary(false);
											runPayRoll({
												"pay_schedule_runs": {
													id: id,
													stage: tabIndex + 2,
												}
											});
										}}
									>
										<StyleTypography
											fontSize={18}
											fontSizeMedium={13}
											fontWeight={"bold"}
											color={"#FFFFFF"}
										>
											Next
										</StyleTypography>
									</StyleButton>
                                </span>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>

				{/* Employees Table */}
				<EmployeesTable
					tabIndex={tabIndex}
					setUpdateEmployeePayRuns={setUpdateEmployeePayRuns}
					updateEmployeePayRuns={updateEmployeePayRuns}
					employee_pay_runs={employee_pay_runs}
					setSelectedEmployee={setSelectedEmployee}
					showSummary={showSummary}
					setShowSummary={setShowSummary}
				/>
			</>
		)
	} else {
		return (
			<ReviewPayroll
				payroll_summary={payroll_summary}
				payroll_id={id}
				showButton={true}
				confirmPayRoll={confirmPayRoll}
				pay_schedule_run={pay_schedule_run}
				push={push}
				viewPaymentBreakdown={viewPaymentBreakdown}
				payments_breakdown={payments_breakdown}
			/>
		)
	}
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const RunPayroll = ({
	me = () => {},
	user = {},
	pay_schedule_run_loading = false,
	employee_pay_runs = [{}],
	employee_pay_run = {},
	pay_schedule_run = {},
	user_loading = false,
	runPayRoll = () => {},
	payroll_summary = {},
	confirmPayRoll = () => {},
	viewPayslip = () => {},
	sendPayslip = () => {},
	viewPaymentBreakdown = () => {},
	payments_breakdown = [],
	employer = {},
	findEmployer = () => {},
	saveHoursWorked = () => {},
	bulkImportPayItems = () => {},
}): JSX.Element => {

	const id = useParams().payRunId;

	const [validationData, setValidationData] = useState({});
	const [showSummary, setShowSummary] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState({});
	const [tabIndex, setTabIndex] = useState(0);
	const [updateEmployeePayRuns, setUpdateEmployeePayRuns] = useState([{}]);
	const [openImportFlow, setOpenImportFlow] = useState(false);

	const [openMenu, setOpenMenu] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);

	const push = useNavigate();

	const validateRow = (rowData, addError, tabIndex, isPayItemImport = false) => {

		if (tabIndex === 0) {
			if (!validationData.payroll_ids?.find(obj => obj?.payroll_id === String(rowData?.payroll_id))) {
				addError("payroll_id", {message: 'There is no employee with this payroll id', level: "error"});
			}

			if (!validationData.pay_items?.find(obj => obj?.name === String(rowData?.item_name))) {
				addError("item_name", {message: 'There is not a pay item with the provided name', level: "error"});
			}

			//Validate Is Custom Salary Rate
			if (validationData.pay_items?.find(obj =>
				obj?.name === String(rowData?.item_name) &&
				(
					(
						rowData?.is_salary_rate?.toLowerCase() !== "yes" &&
						obj?.is_salary
					) ||
					(
						rowData?.is_salary_rate?.toLowerCase() !== "no" &&
						!obj?.is_salary
					)
				)

			)) {
				if (rowData?.is_salary_rate?.toLowerCase() === "yes") {
					addError("is_salary_rate", {message: "This pay item is not a custom salary, please input 'No' in this column.", level: "error"});
				} else {
					addError("is_salary_rate", {message: "This pay item is a custom salary, please input 'Yes' in this column.", level: "error"});
				}
			}

			if (
				rowData?.is_salary_rate?.toLowerCase() === "yes" &&
				rowData?.total_amount
			) {
				addError("total_amount", {message: "Amount must not be filled, when Is Custom Salary Rate is 'Yes'.", level: "error"});
			}

			if (
				rowData?.is_salary_rate?.toLowerCase() !== "yes" &&
				!rowData?.total_amount
			) {
				addError("total_amount", {message: "Amount is required regular pay items.", level: "error"});
			}

			if (
				rowData?.is_salary_rate?.toLowerCase() === "yes" &&
				!rowData?.hours
			) {
				addError("hours", {message: "Hours/Units are required for custom salary rates", level: "error"});
			}

			if (
				rowData?.is_salary_rate?.toLowerCase() === "no" &&
				rowData?.hours
			) {
				addError("hours", {message: "Hours/Units must not be filled, when Is Custom Salary Rate is 'Yes'.", level: "error"});
			}

		} else if (tabIndex === 1) {
			if (!validationData.payroll_ids?.find(obj => obj?.payroll_id === String(rowData?.payroll_id))) {
				addError("payroll_id", {message: 'There is no hourly employee with this payroll id', level: "error"});
			}
		} else if (tabIndex === 2) {
			if (!validationData.payroll_ids?.find(obj => obj?.payroll_id === String(rowData?.payroll_id))) {
				addError("payroll_id", {message: 'There is no hourly employee with this payroll id', level: "error"});
			}

			if (!validationData.pay_items?.find(obj => obj?.name === String(rowData?.item_name)) && !isPayItemImport) {
				addError("item_name", {message: 'There is not a pay item with the provided name', level: "error"});
			}
		}

		return rowData;
	}

	useEffect(() => {
		if (!user.id) {
			me();
		}
	}, [user.id, me]);

	useEffect(() => {
		if (!employer.id) {
			findEmployer();
		}
	}, [employer.id, findEmployer]);

	useEffect(() => {
		runPayRoll({
			"pay_schedule_runs": {
				id: id,
				stage: 1,
			}
		});
	}, [id, runPayRoll]);

	useEffect(() => {
		if (employer?.account_locked) {
			push('/main');
		}

		if (employee_pay_run.id) {
			setSelectedEmployee(employee_pay_run);
		}

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [employee_pay_run, employer?.account_locked, push]);

	useEffect(() => {
		setUpdateEmployeePayRuns(employee_pay_runs);
	}, [employee_pay_runs]);

	if (pay_schedule_run_loading || user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"run-payroll-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Payroll"}
						expanded={!showSummary}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					{/*Screen Title (Pay Date and Tax Period)*/}
					<Grid
						marginBottom={2}
						container
						justifyContent={"space-between"}
					>
						<Grid>
							<StyleTypography
								fontSize={24}
								fontSizeMedium={20}
								fontWeight={"bold"}
							>
								Pay Date: {formatDateFromBackendWithTime(pay_schedule_run.period_end_date)}
							</StyleTypography>
						</Grid>
						<Grid>
							<StyleTypography
								fontSize={24}
								fontSizeMedium={20}
								fontWeight={"bold"}
							>
								Tax Period: {pay_schedule_run.tax_period}
							</StyleTypography>
						</Grid>
					</Grid>

					{/*Tabs*/}
					<Grid
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						item
					>
						<Tabs
							className={"tabs-run-payroll"}
							value={tabIndex}
							variant={"fullWidth"}
							onChange={(_, newValue) => {
								if (newValue < tabIndex) {
									setTabIndex(newValue);
									runPayRoll({
										"pay_schedule_runs": {
											id: id,
											stage: parseInt(newValue + 1),
										}
									});
								}
							}}
						>
							<Tab
								sx={{borderBottom: "5px solid transparent"}}
								label={"Salaried Employees"}
								value={0}
							/>
							<Tab
								sx={{borderBottom: "5px solid transparent"}}
								label={"Input Hours"}
								value={1}
							/>
							<Tab
								sx={{borderBottom: "5px solid transparent"}}
								label={"Hourly Employees"}
								value={2}
							/>
							<Tab
								sx={{borderBottom: "5px solid transparent"}}
								label={"Review Payroll"}
								value={3}
							/>
						</Tabs>
					</Grid>

					{/*Screen Content*/}
					<Grid
						container
					>
						{renderPayrollStepContent(
							tabIndex,
							setTabIndex,
							id,
							updateEmployeePayRuns,
							setUpdateEmployeePayRuns,
							employee_pay_runs,
							runPayRoll,
							openMenu,
							setOpenMenu,
							anchorEl,
							setAnchorEl,
							setOpenImportFlow,
							setSelectedEmployee,
							showSummary,
							setShowSummary,
							payroll_summary,
							confirmPayRoll,
							pay_schedule_run,
							push,
							viewPaymentBreakdown,
							payments_breakdown,
							saveHoursWorked,
							validationData,
							setValidationData
						)}
					</Grid>
				</Grid>

				{/*Employee summary*/}
				{
					showSummary && (
						<Grid
							item
							xl={3}
							lg={3}
							xs={3}
							md={3}
							sm={3}
						>
							<Drawer
								open={true}
								variant="persistent"
								anchor="right"
								sx={{
									width: '24.5313vw',
									flexShrink: 0,
									'& .MuiDrawer-paper': {
										width: '24.5313vw',
									},
								}}
								autoFocus={false}
							>
								<EmployeeSummary
									sendPayslip={sendPayslip}
									viewPayslip={viewPayslip}
									selectedEmployee={selectedEmployee}
									setSelectedEmployee={setSelectedEmployee}
								/>
							</Drawer>
						</Grid>
					)
				}

				{
					!showSummary && (
						<ReactSpreadsheetImport
							id={"react-spreadsheet"}
							isOpen={openImportFlow}
							onClose={() => setOpenImportFlow(false)}
							fields={tabIndex === 1 ? fieldsForHours : fieldsPayItem}
							rowHook={(data, addError) => validateRow(data, addError, tabIndex, tabIndex !== 1)}
							allowInvalidSubmit={false}
							onSubmit={(data) =>
								tabIndex === 1
									? fillHoursOrPayItem(
										updateEmployeePayRuns,
										data?.validData,
										setUpdateEmployeePayRuns,
										true,
										false
									)
									: bulkImportPayItems({
										'pay_schedule_runs': {
											pay_schedule_run_id: id,
											stage: tabIndex + 1,
											import_data: data?.validData,
										}
									})
							}
							customTheme={{
								components: {
									Button: {
										baseStyle: {
											borderRadius: "none",
										},
										variants: {
											solid: {
												bg: '#0160FD',
												color: 'white',
												_hover: {
													bg: '#0140AA',
												},
											},
										},
									},
									UploadStep: {
										baseStyle: {
											dropZoneBorder: "#0160FD"
										},
									},
								},
							}}
						/>
					)
				}
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
		PayScheduleRun,
	} = state;

	return {
		...Profile,
		...Employer,
		...PayScheduleRun,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	viewPayslip: (data) => dispatch(viewPayslip(data)),
	sendPayslip: (data) => dispatch(sendPayslip(data)),
	runPayRoll: (data) => dispatch(runPayRoll(data)),
	saveHoursWorked: (data) => dispatch(saveHoursWorked(data)),
	bulkImportPayItems: (data) => dispatch(bulkImportPayItems(data)),
	confirmPayRoll: (data, push) => dispatch(confirmPayRoll(data, push)),
	viewPaymentBreakdown: (id, openModal) => dispatch(viewPaymentBreakdown(id,openModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunPayroll)