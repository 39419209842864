import {
	GET_PAY_SCHEDULE_RUNS, RUN_PAYROLL
} from "./types";
import Request from "../../utils/Request";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import FileDownload from "js-file-download";
import Axios from "axios";

export const getPayScheduleRuns = (data) => dispatch => {

	dispatch({
		type: GET_PAY_SCHEDULE_RUNS,
		pay_schedule_run_loading: true,
	});

	return new Request.post('/api/employers/pay-schedule-runs', data)
		.then(response => {
			if (response.data.success) {
				dispatch({
					type: GET_PAY_SCHEDULE_RUNS,
					pay_schedule_run_loading: false,
					pay_schedule_runs: response.data.data,
					fps_resubmissions: response.data.fps_resubmissions,
				});
			}

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: GET_PAY_SCHEDULE_RUNS,
				pay_schedule_run_loading: false,
			});
		})
};

export const reopenPayroll = (data, push) => dispatch => {

	dispatch({
		type: GET_PAY_SCHEDULE_RUNS,
		pay_schedule_run_loading: true,
	});

	return new Request.post('/api/employers/reopen-payroll', data)
		.then(response => {
			if (response.data.success) {
				dispatch({
					type: GET_PAY_SCHEDULE_RUNS,
					pay_schedule_run_loading: false,
				});
				push('/main');
			}

		})
		.catch(error => {
			toast.error(error.response.data.message);
			dispatch({
				type: GET_PAY_SCHEDULE_RUNS,
				pay_schedule_run_loading: false,
			});
		})
};

export const runPayRoll = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});

	/*
		Use golang container for running payroll
	 */
	const options = {
		baseURL: process.env.REACT_APP_FP_GOLANG_API,
		withCredentials: false,
		timeout: 960000,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
		}
	};
	// creating the axios instance.
	let runPayrollRequest = Axios.create(options);
	let url = "run-payroll"

	if (data) {
		return new runPayrollRequest.post(url,data)
			.then(response => {
				if (response.data.success) {
					if (response.data.stage < 4) {
						dispatch({
							type: RUN_PAYROLL,
							pay_schedule_run_loading: false,
							employee_pay_runs: response.data.data,
							pay_schedule_run: response.data.pay_schedule_run,
						});

					} else {
						dispatch({
							type: RUN_PAYROLL,
							pay_schedule_run_loading: false,
							payroll_summary: response.data.data,
							pay_schedule_run: response.data.pay_schedule_run,
						});
					}
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
				if (error?.response?.status === 422) {
					toast.error("Payroll already run for this period. Reopen payroll to run again.");
					window.location.href = '/main';
				} else if (error?.response?.status === 500) {
					window.location.href = '/error';
				} else {
					window.location.href = '/error';
				}
			})
	}
};

export const saveHoursWorked = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});

	if (data) {
		return new Request.post("/api/employers/save-hours",data)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false,
						employee_pay_runs: response.data.employee_pay_runs,
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
				if (error.response.status === 422) {
					toast.error("Payroll already run for this period. Reopen payroll to run again.");
					window.location.href = '/main';
				} else if (error.response.status === 500) {
					toast.error("Something went wrong. Please contact support.");
					window.location.href = '/main';
				} else {
					window.location.href = '/main';
				}
			})
	}
};

export const viewPayslip = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/view-payslip',data, { responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank'; // This will open the PDF in a new tab
				document.body.appendChild(link);
				link.click();

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});

			})
			.catch(error => {
				console.error(error);

				if (error.response.status === 422) {
					toast.error("Something went wrong. Please contact support.");
				}

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const sendPayslip = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/send-payslip',data)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false
					});
					toast.success("Payslip sent successfully.")
				}
			})
			.catch(error => {
				console.error(error);

				if (error.response.status === 422) {
					toast.error("Something went wrong. Please contact support.");
				}

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const downloadPayslips = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/download-payslips',data, { responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'payslips.zip');
				document.body.appendChild(link);
				link.click();

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
				toast.success("Download successfully.")
			})
			.catch(error => {
				console.error(error);

				if (error.response.status === 422) {
					toast.error("Something went wrong. Please contact support.");
				}

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const downloadPayrollReport = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/download-payroll-report',data)
			.then(response => {
				const worksheet = XLSX.utils.json_to_sheet(response.data.report);
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
				const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

				FileDownload(
					excelFileContent,
					`payroll-report.xlsx`
				);

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
				toast.success("Download successfully.")
			})
			.catch(error => {
				console.error(error);

				if (error.response.status === 422) {
					toast.error("Something went wrong. Please contact support.");
				}

				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const confirmPayRoll = (data, push) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});

	const submitData = {
		"pay_schedule_runs": {
			id: data.payroll_id,
			is_late: data.checkIsLate,
			late_reason: data.lateReason,
		}
	}

	if (data) {
		return new Request.post('/api/employers/confirm-payroll',submitData)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false,
					});

					push(`/main/completed-payroll?pay_schedule=${data.schedule_name}&tax_period=${data.tax_period}&pay_date=${data.period_end_date}&id=${data.payroll_id}`);
				}

			})
			.catch(error => {
				if (error.response.status === 422) {
					toast.error(error.response.data.message);
				}
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const viewPaymentBreakdown = (data, openModal, isDownload = false) => dispatch => {
	if (data) {
		return new Request.post('/api/employers/view-payment-breakdown',data)
			.then(response => {
				if (response.data.success) {
					if (!isDownload) {
						dispatch({
							type: RUN_PAYROLL,
							payments_breakdown: response.data.data,
						});
						openModal(true);
					} else {
						const worksheet = XLSX.utils.json_to_sheet(response.data.data);
						const workbook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
						const excelFileContent = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

						FileDownload(
							excelFileContent,
							`net-pay-breakdown.xlsx`
						);
					}
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const managePayItem = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});

	/*
		Use golang container for managing pay item
	 */
	const options = {
		baseURL: process.env.REACT_APP_FP_GOLANG_API,
		withCredentials: false,
		timeout: 960000,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
		}
	};

	// creating the axios instance.
	let managePayItemRequest = Axios.create(options);
	let url = "/manage-single-pay-item"

	if (data) {
		return new managePayItemRequest.post(url,data)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false,
						employee_pay_run: response.data.employee,
						employee_pay_runs: response.data.data,
					});
				}

			})
			.catch(error => {
				toast.error("Something went wrong. Please contact support.")
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const bulkImportPayItems = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});

	/*
		Use golang container for bulk import pay item
	 */
	const options = {
		baseURL: process.env.REACT_APP_FP_GOLANG_API,
		withCredentials: false,
		timeout: 960000,
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('fpEmployerToken'),
		}
	};
	const url = "/manage-bulk-pay-items"

	if (data) {
		return new Axios.create(options).post(url,data)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false,
						employee_pay_runs: response.data.data,
					});
				}

			})
			.catch(error => {
				toast.error("Something went wrong. Please contact support.")
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const getEmployeePayRuns = (data) => dispatch => {
	dispatch({
		type: RUN_PAYROLL,
		pay_schedule_run_loading: true
	});
	if (data) {
		return new Request.post('/api/employers/retrieve-pay-runs',data)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false,
						employee_pay_runs: response.data.data,
						pay_schedule_run: response.data.pay_schedule_run,
					});
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					pay_schedule_run_loading: false
				});
			})
	}
};

export const viewSubmittedPayrun = (data, isTableFilter = false) => dispatch => {
	if (!isTableFilter) {
		dispatch({
			type: RUN_PAYROLL,
			pay_schedule_run_loading: true
		});
	} else {
		dispatch({
			type: RUN_PAYROLL,
			table_loading: true,
			employee_pay_runs: [],
		});
	}
	if (data) {
		return new Request.post('/api/employers/view-payroll',data)
			.then(response => {
				if (response.data.success) {
					dispatch({
						type: RUN_PAYROLL,
						pay_schedule_run_loading: false,
						table_loading: false,
						employee_pay_runs: response.data,
						pay_schedule_run: response.data.pay_schedule_run,
						payroll_summary: response.data.payroll_summary,
					});
				}

			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: RUN_PAYROLL,
					table_loading: false,
					pay_schedule_run_loading: false
				});
			})
	}
};