import Grid from "@mui/material/Grid";
import StyleTypography from "../../StyledComponents/StyleTypography";
import {Checkbox, DialogActions, DialogTitle, Divider, InputLabel, MenuItem, Select} from "@mui/material";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import StyleTable from "../../StyledComponents/StyleTable";
import StyleButton from "../../StyledComponents/StyleButton";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import FormControlLabel from "@mui/material/FormControlLabel";
import {formatDateFromBackendWithTime, getYearFromDateBackend} from "../../../utils/Helpers";

function renderPaymentTimeline (itemName: string, itemValue: string) {

	if (typeof itemValue === "string") {
		itemValue = parseFloat(itemValue?.replace('-','') ?? 0).toLocaleString('en-GB', {
			style: 'currency',
			currency: 'GBP',
		});
	} else {
		itemValue = itemValue?.toLocaleString('en-GB', {
			style: 'currency',
			currency: 'GBP',
		});
	}


	return (
		<Grid
			xs={12}
			item
			container
			justifyContent={"space-between"}
			marginBottom={"30px"}
		>
			<Grid
				xs
				item
				container
				alignItems={"center"}
			>
				{/*<Chip label="11 May" className={'payment-timeline'}/>*/}
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"#000000"}
				>
					{itemName}
				</StyleTypography>
			</Grid>
			<Grid>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={itemValue < 0 ? "#FF0000" : "#000000"}
					fontWeight={"bold"}
				>
					{
						itemValue < 0 && "("
					}
					{itemValue}
					{
						itemValue < 0 && ")"
					}
				</StyleTypography>
			</Grid>
		</Grid>
	)
}

function renderTableLine (itemName: string, itemValue: string, bold = false) {
	if (typeof itemValue === "string") {
		itemValue = parseFloat(itemValue?.replace('-','') ?? 0).toLocaleString('en-GB', {
			style: 'currency',
			currency: 'GBP',
		});
	} else {
		itemValue = itemValue?.toLocaleString('en-GB', {
			style: 'currency',
			currency: 'GBP',
		});
	}
	return (
		<Grid
			xs={12}
			item
			container
			justifyContent={"space-between"}
			marginBottom={"16px"}
		>
			<Grid
				xs
				item
				container
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={"#000000"}
					fontWeight={bold ? "bold" : "normal"}
				>
					{itemName}
				</StyleTypography>
			</Grid>
			<Grid>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={13}
					color={itemValue < 0 ? "#FF0000" : "#000000"}
					fontWeight={"bold"}
				>
					{
						itemValue < 0 && "("
					}
					{itemValue}
					{
						itemValue < 0 && ")"
					}
				</StyleTypography>
			</Grid>
		</Grid>
	)
}


/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReviewPayroll = ({
	payroll_summary,
	payroll_id,
	showButton,
	confirmPayRoll,
	pay_schedule_run,
	push,
	viewPaymentBreakdown,
	payments_breakdown = [],
}): JSX.Element => {

	const [lateReason, setLateReason] = useState(" ");

	const [openConfirmation, setOpenConfirmation] = useState(false);

	const [checkIsLate, setCheckIsLate] = useState(false);

	const [openPaymentBreakdown, setOpenPaymentBreakdown] = useState(false);

	const columns = [
		{
			name: 'payroll_id',
			label: 'PAYROLL ID',
		},
		{
			name: 'forename',
			label: 'forename',
			options: {
				display: false,
			},
		},
		{
			name: 'surname',
			label: 'surname',
			options: {
				display: false,
			},
		},
		{
			name: '',
			label: 'Employee',
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => tableMeta.rowData[1] + ' ' + tableMeta.rowData[2]
			},
		},
		{
			name: 'net_pay',
			label: 'Net pay',
			options: {
				customBodyRender: (value) => parseFloat(value ?? 0).toLocaleString('en-GB', {
					style: 'currency',
					currency: 'GBP',
				})
			},
		},
		{
			name: 'account_number',
			label: 'ACC NUMBER',
		},
		{
			name: 'sort_code',
			label: 'SORT CODE',
		}
	];

	const data = {
		payroll_id,
		checkIsLate,
		lateReason,
		period_end_date: formatDateFromBackendWithTime(pay_schedule_run?.period_end_date),
		tax_period: pay_schedule_run?.tax_period,
		schedule_name: pay_schedule_run?.pay_schedule?.name
	}

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			item
			container
			id={'summary-page'}
			justifyContent={"space-around"}
			sx={{marginTop: '50px', paddingBottom: '200px'}}
		>
			<Grid
				xs={6}
				item
				container
			>
				{/*Payments timeline*/}
				<Grid
					container
					item
					xs={12}
				>
					{/*Payments timeline*/}
					<Grid
						item
						xs={12}
						marginBottom={2}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#000000"}
						>
							Payments overview
						</StyleTypography>
					</Grid>

					{renderPaymentTimeline("Net pay", payroll_summary?.net_pay ?? '0.00')}

					{renderPaymentTimeline("Pension", payroll_summary?.total_pension_deducted ?? '0.00')}

					{renderPaymentTimeline("PAYE & NIC", payroll_summary?.total_taxes ?? '0.00')}

				</Grid>

				<Divider className={"divider"} sx={{marginBottom: "30px"}}/>

				{/*Summary*/}
				<Grid
					container
					item
					xs={12}
				>
					{/*Summary*/}
					<Grid
						item
						xs={12}
						marginBottom={2}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							fontWeight={"bold"}
							color={"#000000"}
						>
							Breakdown
						</StyleTypography>
					</Grid>

					{/*Additions*/}
					<Grid
						marginTop={3}
						item
						xs={12}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
							color={"#000000"}
						>
							Additions
						</StyleTypography>
						<>
							{/*Base Pay*/}
							{renderTableLine("Base pay", payroll_summary?.base_pay_amount ?? '0.00')}

							{/*Gross additions*/}
							{renderTableLine("Gross additions", payroll_summary?.gross_additions ?? '0.00')}

							{/*Net additions*/}
							{renderTableLine("Net additions", payroll_summary?.net_additions ?? '0.00')}
						</>

					</Grid>

					{/*Deductions*/}
					<Grid
						marginTop={3}
						item
						xs={12}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
							color={"#000000"}
						>
							Deductions*
						</StyleTypography>
						<>
							{/*Gross deductions*/}
							{renderTableLine("Gross deductions", payroll_summary?.gross_deductions ?? '0.00')}

							{/*Income tax*/}
							{renderTableLine("Income tax", payroll_summary?.income_tax_deducted ?? '0.00')}

							{/*Student loan*/}
							{renderTableLine("Student loan", payroll_summary?.student_loan ?? '0.00')}

							{/*Postgraduate*/}
							{renderTableLine("Postgraduate loan", payroll_summary?.pg_loan ?? '0.00')}

							{/*Attachments of earnings*/}
							{/*{renderTableLine("Attachments of earnings", '0.00')}*/}

							{/*Employees NIC*/}
							{renderTableLine("Employees NIC", payroll_summary?.employee_ni_deducted ?? '0.00')}

							{/*Employees Pension*/}
							{renderTableLine("Employees pension", payroll_summary?.employee_pension_deducted ?? '0.00')}

							{/*Net deductions*/}
							{renderTableLine("Net deductions", payroll_summary?.net_deductions ?? '0.00')}
						</>
					</Grid>

					<Divider className={"divider"} sx={{marginBottom: "30px", marginTop: "10px"}}/>

					{/*Employer NIC*/}
					{renderTableLine("Employer NIC", payroll_summary?.employer_ni_deducted ?? '0.00')}

					{/*Employer Pension*/}
					{renderTableLine("Employer pension", payroll_summary?.employer_pension_deducted ?? '0.00')}

					<Divider className={"divider"} sx={{marginBottom: "30px", marginTop: "10px"}}/>

					{/*Employer Pension*/}
					{renderTableLine("Total payroll cost", payroll_summary?.total_payroll_cost ?? '0.00', true)}

					<small>*Deductions displayed in red indicate a refund.</small>
				</Grid>

			</Grid>

			<Grid
				xs={3.5}
				item
				container
				alignContent={"flex-start"}
			>
				{/*Payments timeline*/}
				<Grid
					container
					item
					xs={12}
					sx={{backgroundColor: '#F8F9FD'}}
					alignContent={"flex-start"}
					paddingLeft={"30px"}
					paddingRight={"30px"}
					paddingTop={"30px"}
					paddingBottom={"30px"}
				>
					{/*To be transferred*/}
					<Grid
						item
						xs={12}
						sx={{marginBottom: "25px"}}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							fontWeight={"bold"}
							color={"#000000"}
						>
							To be transferred
						</StyleTypography>
					</Grid>

					{/*Amount*/}
					<Grid
						item
						xs={12}
					>
						<StyleTypography
							fontSize={30}
							fontSizeMedium={22}
							fontWeight={"bold"}
							color={"#000000"}
						>
							{parseFloat(payroll_summary?.net_pay ?? 0).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
							})}
						</StyleTypography>
					</Grid>

					{/*Subtext*/}
					<Grid
						item
						xs={12}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"#AAAEB7"}
						>
							This is the total amount you have to pay your employees, the total net pay.
						</StyleTypography>
					</Grid>

					{/*View payments due*/}
					<Grid
						item
						xs={12}
						onClick={() => {
							viewPaymentBreakdown({
								"pay_schedule_runs": {
									id: payroll_id,
								}
							},setOpenPaymentBreakdown);
						}}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={"#000000"}
							fontWeight={"bold"}
							className={"view-payments-due"}
						>
							View payments due
						</StyleTypography>
					</Grid>

					<Divider className={"divider"} sx={{marginBottom: "30px", marginTop: "30px"}}/>

					{/*Pension*/}
					<Grid
						item
						xs={12}
						sx={{marginBottom: "25px"}}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							fontWeight={"bold"}
							color={"#000000"}
						>
							Pension
						</StyleTypography>
					</Grid>

					{/*Amount*/}
					<Grid
						item
						xs={12}
					>
						<StyleTypography
							fontSize={30}
							fontSizeMedium={22}
							fontWeight={"bold"}
							color={"#000000"}
						>
							{parseFloat(payroll_summary?.total_pension_deducted ?? 0).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
							})}
						</StyleTypography>
					</Grid>

					{/*Subtext*/}
					<Grid
						item
						xs={12}
					>
						<StyleTypography
							fontSize={16}
							fontSizeMedium={12}
							color={"#AAAEB7"}
						>
							The pension contribution will be collected automatically.
							It will show in your account as Nuapay Re Collegia.
						</StyleTypography>
					</Grid>

					<Divider className={"divider"} sx={{marginBottom: "30px", marginTop: "30px"}}/>

					{/*Employees*/}
					<Grid
						item
						xs={12}
						sx={{marginBottom: "25px"}}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							fontWeight={"bold"}
							color={"#000000"}
						>
							Employees
						</StyleTypography>
					</Grid>

					{/*Number of employees*/}
					<Grid
						item
						xs={12}
					>
						<StyleTypography
							fontSize={30}
							fontSizeMedium={22}
							fontWeight={"bold"}
							color={"#000000"}
						>
							{payroll_summary?.number_of_employees ?? 0}
						</StyleTypography>
					</Grid>

					{
						showButton && (
							<>
								<Divider className={"divider"} sx={{marginBottom: "30px", marginTop: "30px"}}/>

								{/*Is Late checkbox*/}
								<Grid
									marginTop={2}
									marginBottom={2}
									item
									xs={12}
								>
									<FormControlLabel
										control={
											<Checkbox
												value={checkIsLate}
												icon={<CheckBoxOutlineBlankSharpIcon/>}
												checkedIcon={<CheckBoxSharpIcon/>}
												onChange={(e) => {
													if (e.target.checked === false) {
														setLateReason(" ");
													}
													setCheckIsLate(e.target.checked);
												}}
											/>
										}
										label={
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#000000"}
											>
												This payroll will be submitted after payday
											</StyleTypography>
										}
									/>
								</Grid>

								{/*Late Reason*/}
								{
									checkIsLate && (
										<Grid
											item
											xs={12}
											marginTop={2}
										>
											<InputLabel
												className={"default-input-label"}
											>
												Select late reason
											</InputLabel>
											<FormControl
												fullWidth
											>
												<Select
													className={"default-select"}
													id={"select-gender"}
													MenuProps={{
														PaperProps: {
															style: {
																maxHeight: 200, // Adjust the maximum height of the list
															},
														},
													}}
													value={lateReason ?? " "}
													onChange={(e) => {
														setLateReason(e.target.value)
													}}
												>
													<MenuItem
														key={" "}
														value={" "}
														disabled
													>
														Select
													</MenuItem>
													<MenuItem
														key={"A"}
														value={"A"}
													>
														A - Payment to expat by third party or overseas employer
													</MenuItem>
													<MenuItem
														key={"B"}
														value={"B"}
													>
														B - Employment related security
													</MenuItem>
													<MenuItem
														key={"C"}
														value={"C"}
													>
														C - Notional payment: Other
													</MenuItem>
													<MenuItem
														key={"D"}
														value={"D"}
													>
														D - Payment subject to Class 1 NICs but P11/P9D for tax
													</MenuItem>
													<MenuItem
														key={"E"}
														value={"E"}
													>
														E - Micro employer using temporary ‘on or before’ relaxation
													</MenuItem>
													<MenuItem
														key={"F"}
														value={"F"}
													>
														F - Impractical to report work done on the day
													</MenuItem>
													<MenuItem
														key={"G"}
														value={"G"}
													>
														G - Reasonable excuse
													</MenuItem>
													<MenuItem
														key={"H"}
														value={"H"}
														disabled={!pay_schedule_run?.fps_submitted}
													>
														H - Correction to earlier submission
													</MenuItem>
												</Select>
											</FormControl>

										</Grid>
									)
								}

								{/*Submit*/}
								<Grid
									item
									xs={12}
									marginTop={2}
								>
									<Button
										className={'default-black-button'}
										fullWidth
										onClick={() => {
											setOpenConfirmation(true);
										}}
										disabled={checkIsLate && lateReason === " "}
									>
										<StyleTypography
											fontSize={20}
											fontSizeMedium={16}
											fontWeight={"bold"}
											color={"#FFFFFF"}
										>
											SUBMIT
										</StyleTypography>
									</Button>

								</Grid>
							</>
						)
					}

				</Grid>

			</Grid>

			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={openPaymentBreakdown}
				onClose={() => {
					setOpenPaymentBreakdown(false);
				}}
			>
				<DialogTitle>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={28}
						fontWeight={'bold'}
					>
						View payments due
					</StyleTypography>
				</DialogTitle>
				<DialogContent>
					<StyleTable
						data={payments_breakdown}
						columns={columns}
						options={{
							filter: false,
							download: true,
							sort: false,
						}}
						removeFooter={true}
					/>
				</DialogContent>
			</Dialog>

			<Dialog
				fullWidth={true}
				maxWidth={'sm'}
				open={openConfirmation}
				onClose={() => {
					setOpenConfirmation(false);
				}}
			>
				<DialogContent>
					<StyleTypography
						fontSize={28}
						fontSizeMedium={24}
						color={'#0160FD'}
						fontWeight={'bold'}
					>
						CONFIRM PAYROLL
					</StyleTypography>
					<br/>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={16}
						color={'#000000'}
					>
						Do you confirm that the below payroll is correct and is finalised ?
						<ul>
							<li>Tax period: {pay_schedule_run?.tax_period}</li>
							<li>Pay period: {pay_schedule_run?.pay_run_period}</li>
							<li>Tax year: {getYearFromDateBackend(pay_schedule_run?.tax_year?.from)} - {getYearFromDateBackend(pay_schedule_run?.tax_year?.to)}</li>
							{
								checkIsLate && (
									<li>Reason for late submission: {lateReason}</li>
								)
							}
						</ul>
						Once you click on confirm, we will send automatically the payslips to all employees and the required data to HMRC for RTI purposes.
					</StyleTypography>
					{
						(new Date().toISOString().slice(0,10) > pay_schedule_run?.period_end_date && !checkIsLate) && (
							<StyleTypography
								fontSize={22}
								fontSizeMedium={16}
								color={'#FF0000'}
							>
								Please note that the payroll seems to be late and you are not providing a reason for
								the late submission.
							</StyleTypography>
						)
					}
				</DialogContent>
				<DialogActions>
					<StyleButton
						fullWidth={false}
						onClick={() => {
							setOpenConfirmation(false);
						}}
						isWhiteButton={true}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#000000"}
						>
							CANCEL
						</StyleTypography>
					</StyleButton>
					<StyleButton
						fullWidth={false}
						onClick={() => {
							confirmPayRoll(data, push);
						}}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#FFFFFF"}
						>
							CONFIRM
						</StyleTypography>
					</StyleButton>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

export default ReviewPayroll;