import Grid from "@mui/material/Grid";
import {StyleAppBar} from "../../components/StyleAppBar";
import {me} from "../../actions/Profile";
import {connect} from "react-redux";

import logo from "../../images/svg/Logos/full-logo.svg";
import image404 from "../../images/svg/Errors/404.svg";
import StyleTypography from "../../components/StyledComponents/StyleTypography";

const Page404 = (
	{
		user = {},
	},
): JSX.Element => {

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"main-page"}
		>
			<StyleAppBar
				user={user}
			/>

			<Grid
				container
				id={"error-page-container"}
				justifyContent={"center"}
				alignItems={"center"}
				spacing={4}
			>
				<Grid
					id={"error-page-left-inner-container"}
					item
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={6}
				>
					<img src={logo} alt={"FreePayroll logo"}/>

					<StyleTypography
						fontSize={60}
						fontSizeMedium={55}
						fontWeight={"bold"}
						color={"#275EF3"}
						component={"h1"}
					>
						Page Not Found
					</StyleTypography>

					<StyleTypography
						fontSize={18}
						fontSizeMedium={15}
						color={"#ABAEB7"}
					>
						The page you requested cannot be found, it might have expired or been removed.
					</StyleTypography>
				</Grid>

				<Grid
					id={"error-page-right-inner-container"}
					item
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={6}
				>
					<img src={image404} alt={"Not Found"}/>
				</Grid>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = state => {
	const {
		Profile,
	} = state;

	return {
		...Profile,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page404)