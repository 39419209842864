import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../../actions/Profile";
import {LeftMenu} from "../../../components/LeftMenu";
import {StyleAppBar} from "../../../components/StyleAppBar";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import Loading from "../../../components/Loading";
import {findEmployer, updateEmployer} from "../../../actions/Employer";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Tooltip} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import StyleTable from "../../../components/StyledComponents/StyleTable";
import {Delete} from "@mui/icons-material";
import PayScheduleDeleteDialog from "../../../components/Pages/PaySchedule/PayScheduleDeleteDialog";
import {deletePaySchedule, updatePayScheduleStatus} from "../../../actions/PaySchedule";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PaySchedules = ({
	me = () => {},
	user = {},
	user_loading = false,
	employer_loading = false,
	employer = {},
	findEmployer = () => {},
	deletePaySchedule = () => {},
	updatePayScheduleStatus = () => {},
	pay_schedule_loading = false,
}): JSX.Element => {

	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null),
		[openDeleteDialog, setOpenDeleteDialog] = useState(false),
		[payScheduleSelected, setPayScheduleSelected] = useState({});
		// [openEditDialog, setOpenEditDialog] = useState(false);

	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "frequency_code.name",
			label: "Frequency",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "employees_count",
			label: "N of Employees",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "active",
			label: "Status",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						fontWeight={'bold'}
						color={value ? "green" : "red"}
					>
						{value ? "Active" : "Inactive"}
					</StyleTypography>
				),
			}
		},
		{
			name: "id",
			label: "Actions",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta) =>
					<Grid>
						<IconButton
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
								setPayScheduleSelected(employer.pay_schedules.find(paySchedule => paySchedule.id === value));
							}}
						>
							<MoreHorizIcon/>
						</IconButton>
						<Menu
							id={"popover"}
							open={popOver}
							anchorEl={anchorEl}
							onClose={() => {
								setPopOver(false);
								setAnchorEl(null);
								setPayScheduleSelected({});
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							{/*TO DO: Need the design for the change pay schedule table*/}
							{/*<MenuItem*/}
							{/*	className={"popover-list-grid"}*/}
							{/*	onClick={() => {*/}
							{/*		setOpenEditDialog(true);*/}
							{/*	}}*/}
							{/*>*/}
							{/*	<PersonAdd className={"popover-icon"}/>*/}
							{/*	<Typography>Move employees</Typography>*/}
							{/*</MenuItem>*/}

							<MenuItem
								className={"popover-list-grid"}
								onClick={() => {
									push(`/main/company/edit-pay-schedule/${payScheduleSelected.id}`);
								}}
							>
								<ModeEditIcon className={"popover-icon"}/>
								<Typography>Edit</Typography>
							</MenuItem>
							<Tooltip
								title={
									payScheduleSelected.not_draft_pay_run !== null &&
									'This option is disabled because you can not delete a pay schedule after a pay run is submitted.'
								}
							>
								<span>
									<MenuItem
										className={"popover-list-grid"}
										onClick={() => {
											setAnchorEl(null);
											setPopOver(false);
											setOpenDeleteDialog(true);
										}}
										disabled={payScheduleSelected.not_draft_pay_run !== null}
									>
										<Delete className={"popover-icon"}/>
										<Typography>Delete</Typography>
									</MenuItem>
								</span>
							</Tooltip>
							<MenuItem
								className={"popover-list-grid"}
								onClick={() => {
									setPopOver(false);
									updatePayScheduleStatus(payScheduleSelected.id, {
										'pay_schedules': {
											active: !payScheduleSelected?.active,
										}
									}, push);
								}}
							>
								<Grid
									sx={{
										width: '10px',
										height: '10px',
										borderRadius: '50%',
										backgroundColor: payScheduleSelected?.active ? 'red' : 'green',
										cursor: 'pointer',
										marginRight: "12px",
										marginLeft: "3px"
									}}
								/>
								<Typography>{payScheduleSelected?.active === true ? "Inactivate" : "Activate"}</Typography>
							</MenuItem>
						</Menu>

					</Grid>
			}
		},
	];

	const push = useNavigate();

	useEffect(() => {
		me();
		findEmployer(["pay_schedules.frequency_code", "pay_schedules.not_draft_pay_run"]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, findEmployer]);

	if (user_loading || employer_loading || pay_schedule_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"company-pay-schedules-page"}
		>
			<StyleAppBar
				showBackButton={false}
				employer={employer}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Pay Schedules"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							className={"table-container"}
						>
							<StyleTable
								columns={columns}
								data={employer.pay_schedules ?? []}
								options={{
									selectableRowsHeader: false,
									selectableRows: "none",
									responsive: "simple",
									enableNestedDataAccess: ".",
									selectToolbarPlacement: "none",
									print: false,
									download: false,
									search: false,
									viewColumns: false,
									elevation: 0,
									filter: false,
									searchProps: {
										placeholder: "Search schedules",
										classes: {
											input: "default-text-field"
										}
									},
								}}
								removeFooter={true}
								title={"Pay Schedules"}
								addCustomButtons={true}
								customButtons={
									<>
										<Button
											id={'black-button'}
											onClick={() => push("/main/company/add-pay-schedule")}
										>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={"#fff"}
											>
												+ Add pay schedule
											</StyleTypography>
										</Button>
									</>
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				<PayScheduleDeleteDialog
					selectedPaySchedule={payScheduleSelected}
					setSelectedPaySchedule={setPayScheduleSelected}
					openDeleteDialog={openDeleteDialog}
					setOpenDeleteDialog={setOpenDeleteDialog}
					deletePaySchedule={deletePaySchedule}
					paySchedules={employer?.pay_schedules}
				/>
				{/*TO DO: Need the design for the change pay schedule table*/}
				{/*<ChangeEmployeesPayScheduleDialog*/}
				{/*	open={openEditDialog}*/}
				{/*	setOpen={setOpenEditDialog}*/}
				{/*	currentPaySchedule={payScheduleSelected}*/}
				{/*/>*/}
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer,
		PaySchedule,
	} = state;

	return {
		...Profile,
		...Employer,
		...PaySchedule,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	updateEmployer: (id, data) => dispatch(updateEmployer(id, data)),
	deletePaySchedule: (id, data) => dispatch(deletePaySchedule(id, data)),
	updatePayScheduleStatus: (id, data, push) => dispatch(updatePayScheduleStatus(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaySchedules)