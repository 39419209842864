import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {StyleAppBar} from "../../../components/StyleAppBar";
import {me} from "../../../actions/Profile";
import {connect} from "react-redux";
import {findPaySchedule, updatePaySchedule} from "../../../actions/PaySchedule";
import Loading from "../../../components/Loading";
import {LeftMenu} from "../../../components/LeftMenu";
import StyleTypography from "../../../components/StyledComponents/StyleTypography";
import {InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import StyleButton from "../../../components/StyledComponents/StyleButton";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {parseDate} from "../../../utils/Helpers";
import {endOfMonth, format, parseISO} from "date-fns";
import {findEmployer} from "../../../actions/Employer";

function getPayDayOption(payFrequency = "") {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			return [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
			];
		case "M1":
			return [
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
				"24",
				"25",
				"26",
				"27",
				"28",
				"29",
				"30",
				"Last Day of Month",
			];
		default:
			return [];
	}
}

function disableDayOption(payFrequency = "", payDays, date) {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			if (payDays?.week_payday === "Friday") {
				return date.getDay() !== 5;
			} else if (payDays?.week_payday === "Thursday") {
				return date.getDay() !== 4;
			} else if (payDays?.week_payday === "Wednesday") {
				return date.getDay() !== 3;
			} else if (payDays?.week_payday === "Tuesday") {
				return date.getDay() !== 2;
			} else if (payDays?.week_payday === "Monday") {
				return date.getDay() !== 1;
			}
			break;
		case "M1":
			if (payDays?.custom_payday === "Last Day of Month") {
				return endOfMonth(date).getDate() !== date.getDate();
			}
			if (date.getMonth() === 1) {
				if (Number(payDays?.month_payday) > endOfMonth(date).getDate()) {
					return endOfMonth(date).getDate() !== date.getDate();
				}
			}
			return date.getDate() !== Number(payDays?.month_payday);
		default:
			return [];
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const EditPaySchedule = ({
	 findPaySchedule = () => {
	 },
	 me,
	 user,
	 user_loading,
	 pay_schedule_loading,
	 pay_schedule,
	 updatePaySchedule = () => {},
	 employer,
	 findEmployer = () => {},
 }): JSX.Element => {
	const [scheduleRecord, setScheduleRecord] = useState({
		name: "",
		frequency_code: "",
		week_payday: "",
		month_payday: "",
		custom_payday: "",
		starting_period_end_date: "",
		day_rate_method: "",
	});
	const scheduleId = useParams().scheduleId;

	const payFrequencyOption = [
		{
			name: "Weekly",
			code: "W1",
		},
		{
			name: "Fortnightly",
			code: "W2",
		},
		{
			name: "Four Weekly",
			code: "W4",
		},
		{
			name: "Monthly",
			code: "M1",
		},
	];

	useEffect(() => {
		me();
		findEmployer([]);
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findEmployer, me]);

	useEffect(() => {
		if (scheduleId) {
			findPaySchedule(scheduleId, ['not_draft_pay_run']);
		}
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findPaySchedule, scheduleId]);

	useEffect(() => {
		setScheduleRecord(pay_schedule);
	}, [setScheduleRecord, pay_schedule]);

	const push = useNavigate();

	if (user_loading || pay_schedule_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Grid
			id={"company-pay-schedules-page"}
			container
			justifyContent={"center"}
		>
			<StyleAppBar
				backLink={"/main/company/pay-schedules"}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Pay Schedules"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={27}
									fontWeight={"bold"}
								>
									Edit pay schedule
								</StyleTypography>
							</Grid>
						</Grid>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							container
							direction={"row"}
							alignContent={"flex-start"}
							spacing={4}
						>
							{/*Schedule name*/}
							<Grid
								xl={4.5}
								lg={4.5}
								md={6.5}
								sm={6.5}
								xs={6.5}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Schedule name*
								</InputLabel>
								<TextField
									className={"default-text-field"}
									value={scheduleRecord?.name}
									placeholder={"Type Schedule name"}
									onChange={(e) => setScheduleRecord({...scheduleRecord, name: e.target.value})}
									inputProps={{
										className: "bold-input"
									}}
									fullWidth
								/>
							</Grid>

							{/*Pay Frequency*/}
							<Grid
								xl={4.5}
								lg={4.5}
								md={6.5}
								sm={6.5}
								xs={6.5}
								item
								container
								alignItems="center"
							>
								<InputLabel
									className={"default-input-label"}
								>
									Pay frequency*
								</InputLabel>
								<Select
									className={"default-text-field"}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200, // Adjust the maximum height of the list
											},
										},
									}}
									disabled={pay_schedule?.not_draft_pay_run?.id}
									onChange={(e) => {
										setScheduleRecord({
											...scheduleRecord,
											frequency_code: e.target.value,
											starting_period_end_date: null,
											week_payday: "",
											month_payday: "",
											custom_payday: "",
										});
									}}
									value={scheduleRecord?.frequency_code || ""}
									fullWidth
								>
									{
										payFrequencyOption.map((option, index = {}) => (
											<MenuItem key={index} value={option.code}>
												{option.name}
											</MenuItem>
										))
									}
								</Select>
							</Grid>

							{/*Paydays*/}
							<Grid
								xl={4.5}
								lg={4.5}
								md={6.5}
								sm={6.5}
								xs={6.5}
								item
								container
								alignItems={"center"}
							>
								<InputLabel
									className={"default-input-label"}
								>
									Paydays*
								</InputLabel>
								<Select
									className={"default-text-field"}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200, // Adjust the maximum height of the list
											},
										},
									}}
									value={
										(
											scheduleRecord?.frequency_code === "W1" ||
											scheduleRecord?.frequency_code === "W2" ||
											scheduleRecord?.frequency_code === "W4"
										) ? scheduleRecord?.week_payday
											: (scheduleRecord?.month_payday === ""  || scheduleRecord?.month_payday === null)?
												scheduleRecord?.custom_payday
												: scheduleRecord?.month_payday
									}
									onChange={(e) => {
										if (
											scheduleRecord?.frequency_code === "W1" ||
											scheduleRecord?.frequency_code === "W2" ||
											scheduleRecord?.frequency_code === "W4"
										) {
											setScheduleRecord({
												...scheduleRecord,
												week_payday: e.target.value,
												month_payday: "",
												custom_payday: "",
												starting_period_end_date: null,
											});
										} else {
											if (e.target.value === "Last Day of Month") {
												setScheduleRecord({
													...scheduleRecord,
													custom_payday: e.target.value,
													month_payday: "",
													week_payday: "",
													starting_period_end_date: null,
												});
											} else {
												setScheduleRecord({
													...scheduleRecord,
													month_payday: e.target.value,
													custom_payday: "",
													week_payday: "",
													starting_period_end_date: null,
												});
											}
										}
									}}
									disabled={pay_schedule?.not_draft_pay_run?.id}
									fullWidth
								>
									{
										getPayDayOption(scheduleRecord?.frequency_code).map((option, index) => (
											<MenuItem key={index} value={option}>
												{option}
											</MenuItem>
										))
									}
								</Select>
							</Grid>

							{/*First pay date*/}
							<Grid
								xl={4.5}
								lg={4.5}
								md={6.5}
								sm={6.5}
								xs={6.5}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									First pay date*
								</InputLabel>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={enGb}
								>
									<DesktopDatePicker
										className={"date-picker"}
										onChange={(newValue) => {
											setScheduleRecord({...scheduleRecord, starting_period_end_date: newValue})
										}}
										disabled={pay_schedule?.not_draft_pay_run?.id}
										value={parseDate(scheduleRecord?.starting_period_end_date)}
										shouldDisableDate={(day) => {
											return disableDayOption(scheduleRecord?.frequency_code, scheduleRecord, day);
										}}
										slotProps={{ field: { readOnly: true } }}
										enableAccessibleFieldDOMStructure
										minDate={parseISO(employer?.tax_year?.from)}
										maxDate={parseISO(employer?.tax_year?.to)}
									/>
								</LocalizationProvider>
							</Grid>

							{/*Day rate method*/}
							<Grid
								xl={4.5}
								lg={4.5}
								md={6.5}
								sm={6.5}
								xs={6.5}
								item
							>
								<InputLabel
									className={"default-input-label"}
								>
									Day rate method*
								</InputLabel>
								<Select
									className={"default-text-field"}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 200, // Adjust the maximum height of the list
											},
										},
									}}
									value={scheduleRecord?.day_rate_method}
									onChange={(e) =>
										setScheduleRecord({...scheduleRecord, day_rate_method: e.target.value})
									}
									fullWidth
								>
									<MenuItem
										value={"yearly_working_days"}
										key={"yearly_working_days"}
									>
										Yearly working days
									</MenuItem>
									<MenuItem
										value={"calendar_month"}
										key={"calendar_month"}
									>
										Calendar Month
									</MenuItem>
								</Select>
							</Grid>
						</Grid>
						<Grid paddingTop={"40px"}>
							<StyleButton
								onClick={() => {
									updatePaySchedule(scheduleId, {
										'pay_schedules': {
											name: scheduleRecord?.name,
											frequency_code: scheduleRecord?.frequency_code,
											week_payday: (scheduleRecord?.frequency_code === "W1" || scheduleRecord?.frequency_code === "W2" || scheduleRecord?.frequency_code === "W4") ? scheduleRecord?.week_payday : null,
											month_payday: ((scheduleRecord?.frequency_code === "M1") && (scheduleRecord?.custom_payday !== 'Last Day of Month')) ? scheduleRecord?.month_payday : null,
											custom_payday: (scheduleRecord?.frequency_code === "M1") && (scheduleRecord?.custom_payday === 'Last Day of Month') ? scheduleRecord?.custom_payday : null,
											starting_period_end_date: format(parseDate(scheduleRecord?.starting_period_end_date), "yyyy-MM-dd"),
											day_rate_method: scheduleRecord?.day_rate_method,
										}
									}, push);
								}}
								disabled={
									scheduleRecord?.name === "" ||
									scheduleRecord?.frequency_code === "" ||
									(
										(scheduleRecord?.frequency_code === "W1" ||
											scheduleRecord?.frequency_code === "W2" ||
											scheduleRecord?.frequency_code === "W4") &&
										scheduleRecord?.week_payday === ""
									) ||
									(
										scheduleRecord?.frequency_code === "M1" &&
										scheduleRecord?.month_payday === "" &&
										scheduleRecord?.custom_payday === ""
									) ||
									scheduleRecord?.starting_period_end_date === null ||
									scheduleRecord?.day_rate_method === ""
								}
							>
								Save
							</StyleButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PaySchedule,
		Employer,
	} = state;

	return {
		...Profile,
		...PaySchedule,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findPaySchedule: (id, relations = []) => dispatch(findPaySchedule(id, relations)),
	updatePaySchedule: (id, data, push) => dispatch(updatePaySchedule(id, data, push)),
	findEmployer: (id, relations = []) => dispatch(findEmployer(id, relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPaySchedule)