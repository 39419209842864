import Layout from '../../components/common/Layout';
import { Divider } from "@mui/material";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import DateIconBlueIcon from "../../images/svg/date-icon-blue.svg";
import SettingsBlueIcon from "../../images/svg/settings-blue.svg";
import GrowArrowIcon from "../../images/svg/grow-arrow.svg";
import { me } from '../../actions/Profile';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import {findEmployer} from "../../actions/Employer";

function AutomaticEnrolmentMenu({
	me = () => {},
	user,
	findEmployer = () => {},
	employer,
}) {
	useEffect(() => {
		if (user?.id) return;
		me();
		findEmployer([]);
	}, [me, user?.id, findEmployer]);

	const COLLEGIA_DATA = [
		[
			{
				name: "Scheme Name",
				value: "Collegia",
			},
			{
				name: "Scheme Address",
				value: "3 West Way, Buxton Court OX2 0JB, United Kingdom",
			},
			{
				name: "Type of Scheme",
				value: "Personal",
			}
		],
		[
			{
				name: "Employer Pension Scheme Reference (EPSR)",
				value: "Col-" + employer?.company_number ?? "",
			},
			{
				name: "Pension Scheme Tax Reference (PSTR)",
				value: "20004161RJ",
			},
			{
				name: "Pension Scheme Registry (PSR) number",
				value: "12018421"
			}
		]
	]

	return (
		<Layout
			user={user}
			activePage={"Auto Enrolment"}
			showBackButton={false}
		>
			<div id={"auto-enrolment-menu"}>
				<div>
					<StyleTypography fontSize={40} fontSizeMedium={30} fontWeight={"bold"}>
						Workplace pension
					</StyleTypography>
					<StyleTypography fontSize={18} fontSizeMedium={13} color={"#ABAEB7"}>
						Setup your workplace pension for auto enrolment
					</StyleTypography>
				</div>
				<Divider />

				<div className={"auto-enrolment-data"}>
					{COLLEGIA_DATA.map((data, index) => (
						<>
							<div className={"auto-enrolment-data-row"}>
								{data.map((item, index) => (
									<div key={index} className={"auto-enrolment-data-item"}>
										<StyleTypography
											fontSize={16}
											fontSizeMedium={12}
											color={"#98989A"}
										>
											{item.name}
										</StyleTypography>
										<StyleTypography
											fontSize={18}
											fontSizeMedium={16}
											color={"black"}
											fontWeight={"bold"}
											className={"auto-enrolment-data-item-value"}
										>
											{item.value}
										</StyleTypography>
									</div>
								))}
							</div>
							{index !== data.length - 1 && <Divider />}
						</>
					))}
				</div>

				<div className={"navigation-list"}>
					<MenuItem
						title={"Pension payments"}
						subtitle={"View your pension payments"}
						link={"/main/auto-enrolment-payments"}
						icon={DateIconBlueIcon}
					/>
					<MenuItem
						title={"Pension settings"}
						subtitle={"Manage your automatic enrolment settings"}
						link={"/main/auto-enrolment-settings"}
						icon={SettingsBlueIcon}
					/>
					<MenuItem
						title={"Direct debit instruction"}
						subtitle={"Manage your direct debit instruction with Collegia"}
						link={"/main/auto-enrolment-ddi"}
						icon={GrowArrowIcon}
					/>
				</div>
			</div>
		</Layout>
	)
}

function MenuItem({ title, subtitle, link, icon }) {
	return (
		<a className={"navigation-list-item"} href={link}>
			<img src={icon} alt={"icon"} className={"navigation-list-item-icon"} />

			<StyleTypography
				fontSize={24}
				fontSizeMedium={20}
				fontWeight={"bold"}
				color={"black"}
			>
				{title}
			</StyleTypography>

			<StyleTypography
				fontSize={16}
				fontSizeMedium={14}
				color={"#ABAEB7"}
			>
				{subtitle}
			</StyleTypography>
		</a>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer
	} = state;

	return {
		...Profile,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticEnrolmentMenu)