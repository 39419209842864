import {
	CHANGE_PASSWORD,
	CONFIRM_CODE,
	SEND_CODE,
} from "./types";
import Request from "../../utils/Request";

export const sendCodeToEmail = (data) => dispatch => {
	dispatch({
		type: SEND_CODE,
		message: "",
		reduxStep: 0,
		button_loading: true,
	});
	if (data) {
		return new Request.post('/api/employers/users/forgot-password-send-code', data)
			.then(response => {
				if (response?.data?.success) {
					dispatch({
						type: SEND_CODE,
						message: response?.data?.message,
						reduxStep: 1,
						button_loading: false,
					});
				} else {
					dispatch({
						type: CONFIRM_CODE,
						message: response?.data?.error ? response?.data?.message : "",
						reduxStep: 0,
						button_loading: false,
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: SEND_CODE,
					message: error.response?.data?.message,
					button_loading: false,
				});
			})
	}
};

export const confirmForgotPasswordCode = (data) => dispatch => {
	dispatch({
		type: CONFIRM_CODE,
		message: "",
		reduxStep: 1,
		button_loading: true,
	});
	if (data) {
		return new Request.post('/api/employers/users/forgot-password-confirm-code', data)
			.then(response => {
				if (response?.data?.success) {
					dispatch({
						type: CONFIRM_CODE,
						message: "",
						reduxStep: 2,
						button_loading: false,
					});
				} else {
					dispatch({
						type: CONFIRM_CODE,
						button_loading: false,
						message: response?.data?.error ? response?.data?.message : "",
						reduxStep: 1,
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CONFIRM_CODE,
					message: "",
					step: 0,
					button_loading: false,
				});
			})
	}
};

export const changePassword = (data) => dispatch => {
	dispatch({
		type: CHANGE_PASSWORD,
		forgot_password_loading: true,
		message: ""
	});
	if (data) {
		return new Request.post('/api/employers/users/forgot-password-change-password', data)
			.then(response => {
				if (response?.data?.success) {
					dispatch({
						type: CHANGE_PASSWORD,
						forgot_password_loading: false
					});
					window.location.href = "/login";
				} else {
					dispatch({
						type: CHANGE_PASSWORD,
						forgot_password_loading: false,
						message: "Something gets wrong."
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CHANGE_PASSWORD,
					forgot_password_loading: false,
					message: "Something gets wrong."
				});
			})
	}
};