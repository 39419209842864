import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import Button from "@mui/material/Button";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import Check from "../../../../images/svg/check.svg";

const checkItems = (text = ""): JSX.Element => {
	return (
		<Grid
			container
			direction={"row"}
			paddingBottom={"22px"}
			alignItems={"flex-start"}
			display={"flex"}
			flexWrap={"nowrap"}
		>
			<Grid
				item
				width={"20px"}
				height={"20px"}
				marginTop={"5px"}
			>
				<img
					src={Check}
					alt={"check"}
				/>
			</Grid>
			<Grid
				item
				paddingLeft={"8px"}
			>
				<StyleTypography
					fontSize={18}
					fontSizeMedium={12}
					color={"black"}
				>
					{text}
				</StyleTypography>
			</Grid>

		</Grid>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const FirstInformation = ({
	setStep = () => {
	}
}): JSX.Element => {

	return (
		<Grid
			container
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={27}
						fontWeight={"bold"}
					>
						Add Pay Schedule
					</StyleTypography>
				</Grid>
			</Grid>
			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider/>
			</Grid>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
			>
				<Grid
					xl={6}
					lg={6}
					md={6}
					sm={6}
					xs={6}
					item
				>
					<Grid>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={15}
							fontWeight={"bold"}
							color={"black"}
						>
							Why do we need to ask for this?
						</StyleTypography>
					</Grid>
					<Grid
						paddingTop={"15px"}
					>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={12}
							color={"#ABAEB7"}
						>
							Pay schedules refer to the regular intervals at which your
							workers receive their wages or salaries. In other words,
							it is how often you pay them. You can set up more than one pay schedule,
							but each worker can be assigned to only one pay schedule.
						</StyleTypography>
					</Grid>
					<Grid
						width={"250px"}
						height={"60px"}
						paddingTop={"50px"}
					>
						<Button
							className={"default-black-button"}
							fullWidth
							onClick={() => setStep(1)}
						>
							Add Pay Schedule
						</Button>
					</Grid>
				</Grid>
				<Grid
					xl={5}
					lg={5}
					md={5}
					sm={5}
					xs={5}
					item
					paddingLeft={"180px"}
				>
					<Grid
						paddingBottom={"17px"}
					>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={15}
							fontWeight={"bold"}
							color={"black"}
						>
							What to have ready:
						</StyleTypography>
					</Grid>
					{checkItems("Pay period")}
					{checkItems("Pay dates")}
					{checkItems("Desired date of first payroll with FreePayroll")}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default FirstInformation;