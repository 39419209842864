import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import React, {useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import StyleTypography from "../../StyledComponents/StyleTypography";
import {AgeFromDateString} from "age-calculator";
import {formatDateFromBackend, renderPensionStatusColor} from "../../../utils/Helpers";
import {capitalize} from "@mui/material";
import {PensionModal} from "./PensionModal";

const renderGrids = (component1, component2) => {
	return (
		<Grid
			container
			alignItems={"center"}
			justifyContent={"space-between"}
			className={"information-item-grid"}
		>
			<Grid>
				{component1}
			</Grid>
			<Grid>
				{component2}
			</Grid>
		</Grid>
	);
};

export const Pension = ({
    employeeRecord,
    setEmployeeRecord,
    employee,
    updateEmployee,
	employer,
}): JSX.Element => {

	const [open, setOpen] = useState(false);

	return (
		<Grid
			xl={12}
			lg={12}
			md={12}
			sm={12}
			xs={12}
			container
			item
			spacing={2}
			id={"pension"}
		>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				container
				item
				direction={"row"}
				justifyContent={'space-between'}
			>
				<Grid
					xl={5.83}
					lg={5.83}
					md={5.83}
					sm={5.83}
					xs={5.83}
					item
					className={"pension-card"}
				>
					<Grid
						item
						container
						justifyContent={"space-between"}
						alignContent={'center'}
						sx={{
							paddingBottom: "1.527vw"
						}}
					>
						<Grid>
							<StyleTypography fontWeight={"bold"} fontSize={16} fontSizeMedium={13} color={"black"}>
								Pension Settings
							</StyleTypography>
						</Grid>
						<Grid sx={{cursor: "pointer"}} onClick={() => setOpen(true)}>
							<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={16} fontSizeMedium={13} color={"black"}>
								Edit
								<EditIcon className={"icon"}/>
							</StyleTypography>
						</Grid>
					</Grid>
					<Divider className={"divider"}/>
					{
						renderGrids(
							<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
								Pension provider
							</StyleTypography>,
							<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
								{
									employer?.pension_scheme?.pension_scheme_selected ?
										capitalize(employer?.pension_scheme?.pension_scheme_selected) :
										"N/A"
								}
							</StyleTypography>
						)
					}
					<Divider className={"divider"}/>
					{
						renderGrids(
							<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
								Pension Type
							</StyleTypography>,
							<StyleTypography fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
								{employeeRecord.pension_setting?.relief_at_source === true ? 'Relief at source' : 'Salary Sacrifice' ?? "N/A"}
							</StyleTypography>
						)
					}
					<Divider className={"divider"}/>
					{
						renderGrids(
							<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
								Pension Calculation Basis
							</StyleTypography>,
							<StyleTypography fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
								{employeeRecord.pension_setting?.qualifying_earnings === true ? 'Qualifying Earnings' : 'Pensionable Earnings' ?? "N/A"}
							</StyleTypography>
						)
					}
					<Divider className={"divider"}/>
					{
						renderGrids(
							<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
								Eligible for tax relief
							</StyleTypography>,
							<StyleTypography fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
								{employeeRecord.pension_setting?.eligible_for_tax_relief === true ? 'Yes' : 'No' ?? "N/A"}
							</StyleTypography>
						)
					}
					<Divider className={"divider"}/>
					{
						renderGrids(
							<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
								Employee Contribution
							</StyleTypography>,
							<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
								{employeeRecord.pension_setting?.employee_rate_percentage}%
							</StyleTypography>
						)
					}
					<Divider className={"divider"}/>
					{
						renderGrids(
							<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
								Employer Contribution
							</StyleTypography>,
							<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
								{employeeRecord.pension_setting?.employer_rate_percentage}%
							</StyleTypography>
						)
					}
				</Grid>

				<Grid
					xl={5.83}
					lg={5.83}
					md={5.83}
					sm={5.83}
					xs={5.83}
					item
				>
					<Grid
						className={"pension-card"}
					>
						<Grid paddingBottom={"12px"}>
							<StyleTypography fontSize={18} fontSizeMedium={16} fontWeight={'bold'} color={"black"}>
								Eligibility assessment
							</StyleTypography>
						</Grid>
						<Grid paddingBottom={"30px"}>
							<StyleTypography fontSize={18} fontSizeMedium={11} color={"#AAAEB7"}>
								Eligible jobholders earn more than £833 per month, are aged between 22 and their State Pension age. They have to be enrolled into a qualifying workplace pension scheme.
							</StyleTypography>
						</Grid>
						{
							renderGrids(
								<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
									Worker type
								</StyleTypography>,
								<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
									{employeeRecord.pension_assessment?.worker_type ?? "N/A"}
								</StyleTypography>
							)
						}
						<Divider className={"divider"}/>
						{
							renderGrids(
								<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
									Eligibity Date
								</StyleTypography>,
								<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
									{
										employeeRecord?.pension_assessment?.eligibility_date ?
											formatDateFromBackend(employeeRecord?.pension_assessment?.eligibility_date)
											:
											"N/A"
									}
								</StyleTypography>
							)
						}
						<Divider className={"divider"}/>
						{
							renderGrids(
								<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
									Age
								</StyleTypography>,
								<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
									{new AgeFromDateString(employeeRecord.birthdate).age}
								</StyleTypography>
							)
						}
						<Divider className={"divider"}/>
						{
							renderGrids(
								<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
									Status
								</StyleTypography>,
								<Grid container justifyContent={"flex-end"} >
									<Grid className={renderPensionStatusColor(employeeRecord.pension_assessment?.ae_status)}>
										<StyleTypography fontSize={14} fontSizeMedium={13} color={"white"}>
											{employeeRecord.pension_assessment?.ae_status ?? "N/A"}
										</StyleTypography>
									</Grid>
								</Grid>
							)
						}
						<Divider className={"divider"}/>
						{
							renderGrids(
								<StyleTypography textAlign={"start"} fontSize={18} fontSizeMedium={13} color={"black"}>
									Enrolment date
								</StyleTypography>,
								<StyleTypography textAlign={"end"} fontWeight={"bold"} fontSize={18} fontSizeMedium={13} color={"black"}>
									{
										employeeRecord?.pension_assessment?.enrolment_date ?
											formatDateFromBackend(employeeRecord?.pension_assessment?.enrolment_date)
											:
											"N/A"
									}
								</StyleTypography>
							)
						}
					</Grid>

				</Grid>

			</Grid>

			<PensionModal
				open={open}
				setOpen={setOpen}
				employeeRecord={employeeRecord}
				setEmployeeRecord={setEmployeeRecord}
				updateEmployee={updateEmployee}
				employee={employee}
			/>
		</Grid>
	)
}