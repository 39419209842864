import PayrollHistory from "./PayrollHistory";
import PayrollArchive from "./PayrollArchive";
import PayrollActive from "./PayrollActive";
import RunPayroll from "./RunPayroll";
import ViewPayroll from "./ViewPayroll";
import CompletedPayroll from "./CompletedPayroll";

const PayrollPages = {
	PayrollHistory,
	PayrollArchive,
	PayrollActive,
	RunPayroll,
	ViewPayroll,
	CompletedPayroll
}

export default PayrollPages;