import {
	ADD_PAY_SCHEDULE,
	CHANGE_EMPLOYEES_PAY_SCHEDULE,
	DELETE_PAY_SCHEDULE,
	FIND_PAY_SCHEDULE,
	GET_PAY_SCHEDULES,
	UPDATE_PAY_SCHEDULE,
} from "./types";
import Request from "../../utils/Request";
import {GET_EMPLOYER} from "../Employer/types";
import {toast} from "react-toastify";

export const addPaySchedule = (data, isOnboarding = false, push) => dispatch => {

	dispatch({
		type: ADD_PAY_SCHEDULE,
		pay_schedule_loading: true,
	});

	return new Request.post('/api/employers/pay-schedule',data)
		.then(response => {
			dispatch({
				type: ADD_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});

			if (response.data.success) {
				dispatch({
					type: GET_EMPLOYER,
					employer: {
						...response.data.data,
					},
				});

				if (isOnboarding) {
					push("/onboarding/dashboard");
				} else {
					push("/main/company/pay-schedules");
				}
			}

		})
		.catch(error => {
			console.error(error);
			toast.error(error.response.data.message);
			dispatch({
				type: ADD_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});
		})
};

export const getPaySchedule = () => dispatch => {

	dispatch({
		type: GET_PAY_SCHEDULES,
		pay_schedule_loading: true,
	});

	return new Request.get('/api/employers/pay-schedule')
		.then(response => {
			if (response.data.success) {
				dispatch({
					type: GET_PAY_SCHEDULES,
					pay_schedule_loading: false,
					pay_schedules: response.data.data,
				});
			}

		})
		.catch(error => {
			dispatch({
				type: GET_PAY_SCHEDULES,
				pay_schedule_loading: false,
			});
			toast.error(error.response.data.message);
			console.error(error);
		})
};

export const findPaySchedule = (id, relations = []) => dispatch => {

	dispatch({
		type: FIND_PAY_SCHEDULE,
		pay_schedule_loading: true,
	});
	let queryString = '';
	if (relations.length) {
		// Create a string like 'relations[]=relation1&relations[]=relation2'
		queryString = relations.map(relation => `relations[]=${relation}`).join('&');
	}

	return new Request.get(`/api/employers/pay-schedule/${id}?${queryString}`)
		.then(response => {
			if (response.status === 200) {
				dispatch({
					type: FIND_PAY_SCHEDULE,
					pay_schedule_loading: false,
					pay_schedule: response.data,
				});
			}

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: FIND_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});
		})
};

export const updatePaySchedule = (id, data, push) => dispatch => {

	dispatch({
		type: ADD_PAY_SCHEDULE,
		pay_schedule_loading: true,
	});

	return new Request.post(`/api/employers/update-pay-schedule/${id}`,data)
		.then(response => {

			if (response.data.success) {
				push("/main/company/pay-schedules");
			}

		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: ADD_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});

			if (error.response.status === 422) {
				toast.error(error.response.data.message);
			}
		})
};

export const deletePaySchedule = (id, data) => dispatch => {

	if (id) {
		dispatch({
			type: DELETE_PAY_SCHEDULE,
			pay_schedule_loading: true,
		});

		return new Request.post(`/api/employers/delete-pay-schedule/${id}`, data)
			.then(response => {
				dispatch({
					type: DELETE_PAY_SCHEDULE,
					pay_schedule_loading: false,
				});
				dispatch({
					type: GET_EMPLOYER,
					employer: {
						...response.data.data,
					},
				});
				toast.success(response?.data?.message);
			})
			.catch(error => {
				console.error(error);
				if (error.response.status !== 500) {
					toast.error(error.response.data.message ?? "An unexpected error occurred. Please contact support.");
				}
				dispatch({
					type: DELETE_PAY_SCHEDULE,
					pay_schedule_loading: false,
				});
			})
	}
};

export const changeEmployeesPaySchedule = (data) => dispatch => {
	dispatch({
		type: CHANGE_EMPLOYEES_PAY_SCHEDULE,
		pay_schedule_loading: true
	});
	
	return new Request.post(`/api/employers/change-employees-pay-schedule`, data)
		.then(response => {
			dispatch({
				type: CHANGE_EMPLOYEES_PAY_SCHEDULE,
				pay_schedule_loading: false
			});
			toast.success(response?.data?.message);
			window.location.reload();
		})
		.catch(e => {
			console.error(e);
			if (e.response.status !== 500) {
				toast.error(e.response.data.message ?? "An unexpected error occurred. Please contact support.");
			}
			dispatch({
				type: DELETE_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});
		});
};

export const updatePayScheduleStatus = (id, data) => dispatch => {

	dispatch({
		type: UPDATE_PAY_SCHEDULE,
		pay_schedule_loading: true,
	});

	return new Request.post(`/api/employers/update-pay-schedule-status/${id}`,data)
		.then(response => {
			dispatch({
				type: UPDATE_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});
			if (response.data.success) {
				dispatch({
					type: GET_EMPLOYER,
					employer: {
						...response.data.data,
					},
				});
			}
		})
		.catch(error => {
			console.error(error);
			dispatch({
				type: UPDATE_PAY_SCHEDULE,
				pay_schedule_loading: false,
			});
			if (error.response.status === 422) {
				toast.error(error.response.data.message);
			}
		})
};