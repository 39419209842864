import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import {useNavigate} from "react-router-dom";
import {Chip} from "@mui/material";
import {getPayScheduleRuns} from "../../actions/PayScheduleRun";
import StyleTable from "../../components/StyledComponents/StyleTable";
import Button from "@mui/material/Button";
import {findEmployer} from "../../actions/Employer";
import {formatDateFromBackendWithTime} from "../../utils/Helpers";

function renderPayRunStatusColor(status) {
	switch (status) {
		case "Submitted":
			return {
				backgroundColor: "#0360FD",
				color: "#FFFFFF"
			};
		default:
			return {
				backgroundColor: "#C4C4C4",
				color: "#FFFFFF"
			};
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollActivePage = ({
	me = () => {},
	getPayScheduleRuns = () => {},
	user = {
		id: null,
	},
	pay_schedule_runs = [],
	pay_schedule_run_loading = false,
	user_loading = false,
	employer = {},
	findEmployer = () => {},
}): JSX.Element => {

	const push = useNavigate();

	const columns = [
		{
			name: "pay_schedule.name",
			label: "Schedule Name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			},
		},
		{
			name: "tax_period",
			label: "Tax Period",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "pay_run_period",
			label: "Pay Run",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{value}
					</StyleTypography>
				),
			}
		},
		{
			name: "period_end_date",
			label: "Pay Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{formatDateFromBackendWithTime(value)}
					</StyleTypography>
				),
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: true,
				sort: false,
				setCellProps: () => ({className: "font-size"}),
				customBodyRender: (value) => (
					<Chip label={value} className={"mui-chip"} sx={renderPayRunStatusColor(value)} />
				)
			}
		},
		{
			name: "id",
			label: " ",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) =>
					<Grid container alignItems={"center"}>
						<Button
							sx={{display: pay_schedule_runs.filter((item) => item.id === value)[0].active ? 'flex' : 'none'}}
							onClick={() => {
								push(`/main/run-payroll/${btoa(value.toString())}`);
							}}
							disabled={employer?.account_locked}
							className={"run-payroll-button"}
						>
							<StyleTypography
								fontSize={16}
								fontSizeMedium={11}
								color={"#FFFFFF"}
								fontWeight={'bold'}
							>
								Run Payroll
							</StyleTypography>
						</Button>
					</Grid>
			}
		},
	];

	useEffect(() => {
		if (!user.id) {
			me();
		}

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user, push]);

	useEffect(() => {
		getPayScheduleRuns({
			"pay_schedule_runs": {
				page: 'payroll_active',
			}
		});
	}, [getPayScheduleRuns]);

	if (pay_schedule_run_loading || user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"main-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Payroll"}
						activeSubpage={"Active"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							className={"team-member-table-container"}
						>
							<StyleTable
								title={"Draft Pay Runs"}
								columns={columns}
								data={pay_schedule_runs ?? []}
								options={{
									selectableRowsHeader: false,
									selectableRows: "none",
									responsive: "simple",
									enableNestedDataAccess: ".",
									selectToolbarPlacement: "none",
									searchAlwaysOpen: false,
									print: false,
									download: false,
									search: true,
									viewColumns: false,
									elevation: 0,
									filter: false,
									searchProps: {
										placeholder: "Search pay runs",
										classes: {
											input: "default-text-field"
										}
									},
								}}
								removeToolbar={false}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PayScheduleRun,
		Employer,
	} = state;

	return {
		...Profile,
		...PayScheduleRun,
		...Employer,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	getPayScheduleRuns: (data) => dispatch(getPayScheduleRuns(data)),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollActivePage)