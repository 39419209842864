import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Loading from '../components/Loading';
import { connect } from 'react-redux';
import Header from '../components/Header';
import StyleTypography from '../components/StyledComponents/StyleTypography';
import { validateEmail, validatePassword } from '../utils/Helpers';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import {
	changePassword,
	confirmForgotPasswordCode,
	sendCodeToEmail,
} from '../actions/ForgotPassword';
import PinInput from 'react-pin-input';
import closedEye from '../images/svg/closed-eye.svg';
import openEye from '../images/svg/open-eye.svg';
import NewTextField from '../components/common/NewTextField';

const renderContent = (
	step = 0,
	message = '',
	email = '',
	confirmCode = '',
	password = '',
	confirmPassword = '',
	showPassword = false,
	push,
	setStep = () => {},
	setEmail = () => {},
	setConfirmCode = () => {},
	setPassword = () => {},
	setConfirmPassword = () => {},
	setShowPassword = () => {},
	sendCodeToEmail = () => {},
	confirmForgotPasswordCode = () => {},
	changePassword = () => {},
	button_loading = false
) => {
	switch (step) {
		case 0:
			return (
				<Grid
					className={'forgot-password-input-email'}
					container
					direction={'column'}
				>
					<Grid className={'forgot-password-margin-top'}>
						<StyleTypography
							fontSize={50}
							fontSizeMedium={37}
							fontWeight={'bold'}
							textAlign={'center'}
						>
							Password? We can help!
						</StyleTypography>
					</Grid>
					<Grid>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							color={'black'}
							textAlign={'center'}
						>
							Please enter your email address. You will receive a link to create
							a new password via email.
						</StyleTypography>
					</Grid>
					<NewTextField
						className={'forgot-password-text-field'}
						multiline={true}
						fullWidth
						placeholder={'Email'}
						error={(!validateEmail(email) && email.length > 0) || message}
						helperText={
							<Grid>
								{!validateEmail(email) && email.length > 0 ? "Please enter a valid email" : ""}
								{!validateEmail(email) && email.length > 0 && message.length > 0 ? <br/> : ""}
								{message}
							</Grid>
						}
						onChange={(event) => setEmail(event.target.value)}
					/>
					<Button
						className={'forgot-password-confirm-button'}
						disabled={!validateEmail(email) || button_loading}
						fullWidth
						onClick={() =>
							sendCodeToEmail({
								users: {
									email: email,
								},
							})
						}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={
								!validateEmail(email) || button_loading ? '#C9CCD1' : 'white'
							}
						>
							CONFIRM
						</StyleTypography>
					</Button>
					<Button
						className={'forgot-password-cancel-button'}
						fullWidth
						onClick={() => push(`/login`)}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={'black'}
						>
							CANCEL
						</StyleTypography>
					</Button>
				</Grid>
			);
		case 1:
			return (
				<Grid
					className={'forgot-password-input-confirm-code'}
					container
					direction={'column'}
				>
					<Grid>
						<StyleTypography
							fontSize={50}
							fontSizeMedium={37}
							fontWeight={'bold'}
							textAlign={'center'}
						>
							Enter the code to confirm your email
						</StyleTypography>
					</Grid>
					<Grid>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							color={'black'}
							textAlign={'center'}
						>
							Enter the code we sent to the address <b>{email}</b> to confirm
							your email
						</StyleTypography>
					</Grid>
					<PinInput
						length={6}
						type={'numeric'}
						onChange={(value) => setConfirmCode(value)}
						onComplete={(value) => setConfirmCode(value)}
					/>
					<StyleTypography
						fontSize={14}
						color={'red'}
						textAlign={'flex-start'}
					>
						{message}
					</StyleTypography>
					<Button
						className={'forgot-password-confirm-button'}
						disabled={confirmCode.length < 6 || button_loading}
						onClick={() =>
							confirmForgotPasswordCode({
								users: {
									email: email,
									code: confirmCode,
								},
							})
						}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={
								confirmCode.length < 6 || button_loading ? '#C9CCD1' : 'white'
							}
						>
							CONFIRM
						</StyleTypography>
					</Button>
					<Button
						className={'forgot-password-cancel-button'}
						onClick={() => {
							setStep(0);
							setPassword({
								valid: null,
								character: null,
								number: null,
								length: null,
								stringPassword: '',
							});
							setConfirmCode('');
							setEmail('');
						}}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={'black'}
						>
							CANCEL
						</StyleTypography>
					</Button>
				</Grid>
			);
		case 2:
			return (
				<Grid
					className={'forgot-password-input-reset-password'}
					container
					direction={'column'}
				>
					<Grid>
						<StyleTypography
							fontSize={50}
							fontSizeMedium={37}
							fontWeight={'bold'}
							textAlign={'center'}
						>
							Set a new password
						</StyleTypography>
					</Grid>
					<Grid>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							color={'black'}
							textAlign={'center'}
						>
							Please create a new password to access your account
						</StyleTypography>
					</Grid>

					<Grid container>
						<NewTextField
							className={'new-password-field'}
							required
							autoComplete={'new-password'}
							value={password?.stringPassword}
							onChange={(e) => {
								let validPassword = validatePassword(e.target.value);
								setPassword({
									...password,
									valid: validPassword.valid,
									character: validPassword.character,
									number: validPassword.number,
									length: validPassword.length,
									stringPassword: e.target.value,
								});
							}}
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<img
										src={showPassword ? closedEye : openEye}
										alt={'show password'}
										onClick={() => setShowPassword(!showPassword)}
										className={'eyes-icon'}
									/>
								),
							}}
						/>
					</Grid>

					<NewTextField
						error={
							!(password?.stringPassword === confirmPassword) &&
							confirmPassword !== ''
						}
						required
						autoComplete={'new-password'}
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<img
									src={showPassword ? closedEye : openEye}
									alt={'show password'}
									onClick={() => setShowPassword(!showPassword)}
									className={'eyes-icon'}
								/>
							),
						}}
					/>
					{password?.stringPassword.length > 0 &&
						(!password?.valid ||
							!password?.character ||
							!password?.number ||
							!password?.length) && (
							<Grid
								justifyContent={'flex-start'}
								container
							>
								<StyleTypography
									fontSize={14}
									fontSizeMedium={11}
									color={'red'}
									textAlign={'start'}
								>
									Your password must have 8 or more characters, use at least one
									letter and one number.
								</StyleTypography>
							</Grid>
						)}
					{password?.valid &&
						password?.character &&
						password?.number &&
						password?.length &&
						password.stringPassword !== confirmPassword &&
						confirmPassword.length > 0 && (
							<Grid container>
								<StyleTypography
									fontSize={14}
									fontSizeMedium={11}
									textAlign={'flex-start'}
								>
									Password don't match!
								</StyleTypography>
							</Grid>
						)}
					<Button
						className={'forgot-password-confirm-button'}
						disabled={
							password?.stringPassword !== confirmPassword ||
							password?.stringPassword.length === 0 ||
							confirmPassword.length === 0 ||
							!password?.valid ||
							!password?.character ||
							!password?.number ||
							!password?.length
						}
						onClick={() =>
							changePassword({
								users: {
									email: email,
									password: password?.stringPassword,
								},
							})
						}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={confirmCode.length < 6 ? '#C9CCD1' : 'white'}
						>
							CONFIRM
						</StyleTypography>
					</Button>
					<Button
						className={'forgot-password-cancel-button'}
						onClick={() => {
							setStep(0);
							setPassword({
								valid: null,
								character: null,
								number: null,
								length: null,
								stringPassword: '',
							});
							setConfirmCode('');
							setConfirmPassword('');
						}}
					>
						<StyleTypography
							fontSize={25}
							fontSizeMedium={18}
							fontWeight={'bold'}
							color={'black'}
						>
							CANCEL
						</StyleTypography>
					</Button>
				</Grid>
			);
		default:
			return <></>;
	}
};

const ForgotPassword = ({
	forgot_password_loading = false,
	button_loading = false,
	reduxStep = 0,
	message = '',
	sendCodeToEmail = () => {},
	confirmForgotPasswordCode = () => {},
	changePassword = () => {},
}): JSX.Element => {
	const [step, setStep] = useState(0),
		[email, setEmail] = useState(''),
		[confirmCode, setConfirmCode] = useState(''),
		[password, setPassword] = useState({
			valid: null,
			character: null,
			number: null,
			length: null,
			stringPassword: '',
		}),
		[confirmPassword, setConfirmPassword] = useState(''),
		[showPassword, setShowPassword] = useState(false),
		push = useNavigate();

	useEffect(() => {
		setStep(reduxStep);
	}, [reduxStep]);

	if (forgot_password_loading) {
		return <Loading />;
	}

	return (
		<Grid
			id={'forgot-password'}
			container
			justifyContent={'center'}
		>
			<Header />
			<Grid className={'forgot-password-margin-top'}>
				{renderContent(
					step,
					message,
					email,
					confirmCode,
					password,
					confirmPassword,
					showPassword,
					push,
					setStep,
					setEmail,
					setConfirmCode,
					setPassword,
					setConfirmPassword,
					setShowPassword,
					sendCodeToEmail,
					confirmForgotPasswordCode,
					changePassword,
					button_loading
				)}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	const { ForgotPassword } = state;

	return {
		...ForgotPassword,
	};
};

const mapDispatchToProps = (dispatch) => ({
	sendCodeToEmail: (data) => dispatch(sendCodeToEmail(data)),
	confirmForgotPasswordCode: (data) =>
		dispatch(confirmForgotPasswordCode(data)),
	changePassword: (data) => dispatch(changePassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
