import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGb from "date-fns/locale/en-GB";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { endOfMonth, parseISO } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import 'react-calendar/dist/Calendar.css';
import Button from "@mui/material/Button";
import Help from "../../../../images/svg/help-icon.svg"
import StyleButton from "../../../StyledComponents/StyleButton";
import { useNavigate } from "react-router-dom";
import {resolveTaxPeriodAndPeriodDates} from "../../../../utils/Helpers";
import {PayScheduleConfirmDialog} from "../../PaySchedule/PayScheduleConfirmDialog";

export function getPayDayOption(payFrequency = "") {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			return [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
			];
		case "M1":
			return [
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23",
				"24",
				"25",
				"26",
				"27",
				"28",
				"29",
				"30",
				"Last Day of Month",
			];
		default:
			return [];
	}
}

function disableDayOption(payFrequency = "", payDays, date) {
	switch (payFrequency) {
		case "W1":
		case "W2":
		case "W4":
			if (payDays === "Friday") {
				return date.getDay() !== 5;
			} else if (payDays === "Thursday") {
				return date.getDay() !== 4;
			} else if (payDays === "Wednesday") {
				return date.getDay() !== 3;
			} else if (payDays === "Tuesday") {
				return date.getDay() !== 2;
			} else if (payDays === "Monday") {
				return date.getDay() !== 1;
			}
			break;
		case "M1":
			if (payDays === "Last Day of Month") {
				return endOfMonth(date).getDate() !== date.getDate();
			}
			if (date.getMonth() === 1) {
				if (Number(payDays) > endOfMonth(date).getDate()) {
					return endOfMonth(date).getDate() !== date.getDate();
				}
			}
			return date.getDate() !== Number(payDays);
		default:
			return [];
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayScheduleInformation = ({
	addPaySchedule = () => { },
	employer,
	isOnboarding,
}): JSX.Element => {
	const [scheduleName, setScheduleName] = useState(""),
		payFrequencyOption = [
			{
				name: "Weekly",
				code: "W1",
			},
			{
				name: "Fortnightly",
				code: "W2",
			},
			{
				name: "Four Weekly",
				code: "W4",
			},
			{
				name: "Monthly",
				code: "M1",
			},
		],
		[payFrequency, setPayFrequency] = useState("Select Pay frequency"),
		[payDays, setPayDays] = useState("Select Pay Day"),
		[dayRateMethod, setDayRateMethod] = useState("yearly_working_days"),
		[firstPayDate, setFirstPayDate] = useState(null),
		[taxPeriod, setTaxPeriod] = useState(""),
		[openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const [openInfoModal, setOpenInfoModal] = useState(false);
	const push = useNavigate();

	useEffect(() => {
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, []);

	return (
		<Grid
			container
		>
			{/*Page Title*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
			>
				<Grid>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={27}
						fontWeight={"bold"}
					>
						Create a new pay schedule
					</StyleTypography>
				</Grid>
			</Grid>

			<Grid
				paddingTop={"30px"}
				paddingBottom={"50px"}
				width={"100%"}
			>
				<Divider />
			</Grid>

			{/*Pay Schedule Information*/}
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				direction={"row"}
				alignContent={"flex-start"}
				spacing={4}
			>
				{/*Pay Schedule Name*/}
				<Grid
					xl={4.5}
					lg={4.5}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
				>
					<InputLabel
						className={"default-input-label"}
					>
						Schedule name*
					</InputLabel>
					<TextField
						className={"default-text-field"}
						value={scheduleName}
						placeholder={"Type Schedule name"}
						onChange={(e) => setScheduleName(e.target.value)}
						fullWidth
						inputProps={{
							className: "bold-input"
						}}
					/>
				</Grid>

				{/*Pay Frequency*/}
				<Grid
					xl={4.5}
					lg={4.5}
					md={6.5}
					sm={6.5}
					xs={6.5}
					item
					container
					alignItems="center"
				>
					<InputLabel
						className={"default-input-label"}
					>
						Pay frequency*
					</InputLabel>
					<Select
						className={"default-text-field"}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 200, // Adjust the maximum height of the list
								},
							},
						}}
						fullWidth
						value={payFrequency}
						onChange={(e) => {
							setPayFrequency(e.target.value);
							setPayDays("Select Pay Day");
							setFirstPayDate(null);
						}}
					>
						{
							<MenuItem
								value={"Select Pay frequency"}
								key={""}
								disabled
							>
								Select Pay frequency
							</MenuItem>
						}
						{
							payFrequencyOption.map((option, index = {}) => (
								<MenuItem key={index} value={option.code}>
									{option.name}
								</MenuItem>
							))
						}
					</Select>
				</Grid>

				{/*Paydays*/}
				{
					(scheduleName.length > 0 && payFrequency !== "Select Pay frequency") && (
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
							container
							alignItems={"center"}
						>
							<InputLabel
								className={"default-input-label"}
							>
								Paydays*
							</InputLabel>
							<Select
								className={"default-text-field"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								value={payDays}
								fullWidth
								onChange={(e) => {
									setPayDays(e.target.value);
									setFirstPayDate(null);
								}}
							>
								<MenuItem value={"Select Pay Day"} key={""} disabled>
									Select Pay Day
								</MenuItem>
								{
									getPayDayOption(payFrequency).map((option, index) => (
										<MenuItem key={index} value={option}>
											{option}
										</MenuItem>
									))
								}
							</Select>
						</Grid>
					)
				}

				{/*First Pay Date*/}
				{
					(scheduleName.length > 0 && payFrequency !== "Select Pay frequency") && (
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
						>
							<InputLabel
								className={"default-input-label"}
							>
								First pay date*
							</InputLabel>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={enGb}
							>
								<DesktopDatePicker
									className={"date-picker"}
									onChange={(newValue) => {
										setFirstPayDate(newValue);
										if (payFrequency !== "Select Pay frequency" && newValue != null) {
											setTaxPeriod(resolveTaxPeriodAndPeriodDates(
												payFrequency,
												newValue,
												payDays === "Last Day of Month",
											)["tax_period"])
										}
									}}
									value={firstPayDate}
									shouldDisableDate={(day) => {
										return disableDayOption(payFrequency, payDays, day);
									}}
									slotProps={{ field: { readOnly: true } }}
									enableAccessibleFieldDOMStructure
									minDate={parseISO(employer?.tax_year?.from)}
									maxDate={parseISO(employer?.tax_year?.to)}
								/>
							</LocalizationProvider>
						</Grid>
					)
				}

				{/*Day rate method*/}
				{
					(scheduleName.length > 0 && payFrequency !== "Select Pay frequency") && (
						<Grid
							xl={4.5}
							lg={4.5}
							md={6.5}
							sm={6.5}
							xs={6.5}
							item
							container
						>
							<InputLabel
								className={"default-input-label"}
							>
								Day rate method*
							</InputLabel>
							<img
								src={Help}
								alt={"help"}
								style={{
									width: "17px",
									height: "17px",
									marginLeft: "7px",
									cursor: "pointer",
								}}
								onClick={() => setOpenInfoModal(true)}
							/>
							<Select
								className={"default-text-field"}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: 200, // Adjust the maximum height of the list
										},
									},
								}}
								fullWidth
								value={dayRateMethod}
								onChange={(e) => setDayRateMethod(e.target.value)}
							>
								<MenuItem
									value={"yearly_working_days"}
									key={"yearly_working_days"}
								>
									Yearly working days
								</MenuItem>
								<MenuItem
									value={"calendar_month"}
									key={"calendar_month"}
								>
									Calendar Month
								</MenuItem>
							</Select>
						</Grid>
					)
				}
			</Grid>
			{/*Tax Period method*/}
			<Grid paddingTop={"40px"}>
				<Button
					className={"default-black-button"}
					disabled={
						scheduleName.length === 0 ||
						payFrequency.length === 0 ||
						payDays === "Select Pay Day" ||
						firstPayDate === null
					}
					onClick={() =>
						setOpenConfirmDialog(true)
					}
					style={{
						minWidth: "16vw",
					}}
				>
					Continue
				</Button>
			</Grid>
			<Dialog
				open={openInfoModal}
				onClose={() => setOpenInfoModal(false)}
				id={"salary-modal"}
				fullWidth={true}
				maxWidth={'md'}
			>
				<DialogTitle>
					<StyleTypography
						fontSize={35}
						fontSizeMedium={28}
						fontWeight={'bold'}
					>
						Day rate method
					</StyleTypography>
				</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							The "day rate" method is a way of calculating payment for work done on a daily basis. It is used to calculate the pay for:
							<ul>
								<li>New starters that join mid period</li>
								<li>Leavers that leave in the middle of a period</li>
								<li>Statutory schedules ( sickness, maternity, and paternity)</li>
							</ul>
						</Grid>

						{/*Yearly working days*/}
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<strong>Yearly working days:</strong>
							<ul>
								<li style={{ marginBottom: '20px' }}>This method uses how many working days there are in a year (52 weeks * number of working days in work pattern).</li>
								<li>
									Example: A team member has a maternity leave start date of 18th June. The pay period runs from 1st to 31st July 2023. They are on a £45,000.00 salary,
									have a use work pattern day rate method, and the company is set to use a calendar year calculation. Their work pattern has Monday, Tuesday, Thursday and Friday as working days.
									Their pay for July will be calculated as 45,000.00 / (4 working days per week * 52 weeks per year) * 9 days worked.
								</li>
							</ul>
						</Grid>

						{/*Calendar month*/}
						<Grid
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<strong>Calendar month:</strong>
							<ul>
								<li style={{ marginBottom: '20px' }}>This method uses how many working days there are in the pay period.</li>
								<li>
									Example: A team member has a maternity leave start date of 18th June. The pay period runs from 1st to 31st July 2023. They are on a £45,000.00 salary,
									have a use work pattern day rate method, and the company is set to use a pay period calculation. Their work pattern has Monday, Tuesday, Thursday and
									Friday as working days. Their pay for July will be calculated as 45,000.00 / 12 periods / 17 working days in the period * 9 days worked.
								</li>
							</ul>
						</Grid>

						<Grid
							marginTop={3}
							item
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<Grid
								item
								xs={4}
							>
								<StyleButton
									onClick={() => setOpenInfoModal(false)}
								>
									I understood
								</StyleButton>
							</Grid>

						</Grid>
					</Grid>

				</DialogContent>
			</Dialog>
			<PayScheduleConfirmDialog
				open={openConfirmDialog}
				setOpen={setOpenConfirmDialog}
				addPaySchedule={addPaySchedule}
				scheduleName={scheduleName}
				payFrequency={payFrequency}
				payDays={payDays}
				firstPayDate={firstPayDate}
				dayRateMethod={dayRateMethod}
				isOnboarding={isOnboarding}
				push={push}
				taxPeriod={taxPeriod}
			/>
		</Grid>
	);
}

export default PayScheduleInformation;