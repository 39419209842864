import Grid from "@mui/material/Grid";
import React, {useEffect, useReducer, useState} from "react";
import {StyleAppBar} from "../../components/StyleAppBar";
import {LeftMenu} from "../../components/LeftMenu";
import {me} from "../../actions/Profile";
import {connect} from "react-redux";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import money from "../../images/svg/money-add-icon.svg";
import SalaryItem from "../../components/Pages/Company/Salaries/SalaryItem";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {createSalaryItem, findEmployer, removeSalaryItem} from "../../actions/Employer";
import {CompanySalaryModal} from "../../components/Pages/Company/Salaries/CompanySalaryModal";

const Salaries = ({
	me = () => {},
	user = {},
	createSalaryItem = () => {},
	findEmployer = () => {},
	employer = {
		salary_items: {}
	},
	employer_loading = false,
	user_loading = false,
	removeSalaryItem = () => {}
}): JSX.Element => {
	useEffect(() => {
		me();
		findEmployer(["salary_items"]);
	}, [me, findEmployer])

	const [open, setOpen] = useState(false);

	const [salary, setSalary] = useReducer(
		(state, updates) => ({...state, ...updates}),
		{
			type: "Overtime",
			description: "",
			pensionable: true,
			multiplier: null,
			period: "",
			rate: null,
			code: "",
		}
	);

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"company-salary-page"}
		>
			<StyleAppBar
				showBackButton={false}
				backLink={"/main"}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Company"}
						activeSubpage={"Salaries"}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Grid>
								<StyleTypography
									fontSize={40}
									fontSizeMedium={30}
									fontWeight={"bold"}
								>
									Salaries
								</StyleTypography>
							</Grid>
							<Grid
								className={"sub-title-container"}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#ABAEB7"}
									component={"h2"}
								>
									Here you can set custom salaries for your employees.
								</StyleTypography>
							</Grid>
						</Grid>
						<Divider className={"divider"}/>
						<Grid
							container
							id={"salaries-container"}
						>
							{
								employer?.salary_items?.length > 0 &&
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									sx={{
										marginBottom: "10px",
									}}
								>
									<StyleTypography
										color={"#000"}
										fontSize={16}
										fontSizeMedium={16}
									>
										Salary Type
									</StyleTypography>
								</Grid>
							}
							<Grid
								item
								xs={4.9}
								sm={4.9}
								md={4.9}
								lg={4.9}
								xl={4.9}
								id={"left-container"}
							>
								{
									employer?.salary_items?.length > 0 &&
									<Grid
										id={"salary-items-box"}
									>
										{
											Object.values(employer?.salary_items).map((item, key) => (
												<SalaryItem
													salary_item={item}
													last={key === employer?.salary_items?.length - 1}
													key={key}
													setSalary={setSalary}
													setOpen={setOpen}
													removeSalaryItem={removeSalaryItem}
												/>
											))
										}
									</Grid>
								}
								<Button
									className={"add-company-salary-button"}
									onClick={() => setOpen(!open)}
									disabled={
										employer_loading ||
										user_loading
									}
								>
									<Add className={"add-icon"}/>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
										color={"#FFF"}
										fontWeight={"bold"}
									>
										Create Salary Type
									</StyleTypography>
								</Button>
							</Grid>
							<Grid
								item
								xs={4.9}
								sm={4.9}
								md={4.9}
								lg={4.9}
								xl={4.9}
								id={"right-container"}
							>
								<Grid
									id={"company-salaries-information-container"}
								>
									<img src={money} alt={"Add salary item"}/>

									<StyleTypography
										color={"#AAAEB7"}
										fontSize={18}
										fontSizeMedium={13}
									>
										Set up Company specific salaries and choose if they should
										be calculated on hours or units.
									</StyleTypography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<CompanySalaryModal
				open={open}
				setOpen={setOpen}
				createSalaryItem={createSalaryItem}
				salary={salary}
				setSalary={setSalary}
				employer_loading={employer_loading}
			/>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Employer
	} = state;

	return {
		...Profile,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	createSalaryItem: (salary) => dispatch(createSalaryItem(salary)),
	removeSalaryItem: (id) => dispatch(removeSalaryItem(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Salaries);