import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import Loading from "../../components/Loading";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Divider from "@mui/material/Divider";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import DownloadIcon from "../../images/svg/ReportsIcon/download-icon.svg";
import DownloadReportDialog from "./DownloadReportDialog";
import {format, parseISO} from "date-fns";
import IconButton from "@mui/material/IconButton";
import {downloadEPS, getEpsReport, submitEPS} from "../../actions/Reports";
import {findEmployer} from "../../actions/Employer";
import StyleButton from "../../components/StyledComponents/StyleButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DialogContent from "@mui/material/DialogContent";
import {DialogActions} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsEps = ({
    me = () => {},
    user = {},
    user_loading = false,
    report_loading = false,
    eps_reports = [],
    getEpsReport = () => {},
    downloadEPS = () => {},
	submitEPS = () => {},
	employer = {},
	employer_loading = false,
	findEmployer = () => {},
}): JSX.Element => {

	const taxYearStart ='20' + employer?.tax_year?.formated_tax_year.split('-')[0]
	const taxYearEnd ='20' + employer?.tax_year?.formated_tax_year.split('-')[1]

	const taxMonths = {
		1: `Month 1: 6th April ${taxYearStart} - 5th May ${taxYearStart}`,
		2: `Month 2: 6th May ${taxYearStart} - 5th June ${taxYearStart}`,
		3: `Month 3: 6th June ${taxYearStart} - 5th July ${taxYearStart}`,
		4: `Month 4: 6th July ${taxYearStart} - 5th August ${taxYearStart}`,
		5: `Month 5: 6th August ${taxYearStart} - 5th September ${taxYearStart}`,
		6: `Month 6: 6th September ${taxYearStart} - 5th October ${taxYearStart}`,
		7: `Month 7: 6th October ${taxYearStart} - 5th November ${taxYearStart}`,
		8: `Month 8: 6th November ${taxYearStart} - 5th December ${taxYearStart}`,
		9: `Month 9: 6th December ${taxYearStart} - 5th January ${taxYearEnd}`,
		10: `Month 10: 6th January ${taxYearEnd} - 5th February ${taxYearEnd}`,
		11: `Month 11: 6th February ${taxYearEnd} - 5th March ${taxYearEnd}`,
		12: `Month 12: 6th March ${taxYearEnd} - 5th April ${taxYearEnd}`
	};

	const [epsDownloadArray, setEpsDownloadArray] = useState([]),
		[title, setTitle] = useState(""),
		[open, setOpen] = useState(false),
		[taxMonthFigures, setTaxMonthFigures] = useState({}),
		[epsId, setEpsId] = useState(0);

	const [openEpsModal, setOpenEpsModal] = useState(false);
	const [epsMode, setEpsMode] = useState(''),
	[noPaymentPeriod, setNoPaymentPeriod] = useState(0);

	const [popOver, setPopOver] = useState(false),
		[anchorEl, setAnchorEl] = useState(null);


	const columns = [
		{
			name: "created_at",
			label: "Submitted Date",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => {
					return (
						<StyleTypography
							color={"#000000"}
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
						>
							{format(parseISO(value), "dd/MM/yyyy hh:mm:ss")}
						</StyleTypography>
					)
				}

			},
		},
		{
			name: "is_error",
			label: "",
			options: {
				filter: false,
				display: false,
				sort: false,
				customBodyRender: () => ""
			}
		},
		{
			name: "is_success",
			label: "",
			options: {
				filter: false,
				display: false,
				sort: false,
				customBodyRender: () => ""
			}
		},
		{
			name: "",
			label: "Status",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value, tableMeta) => {
					if (tableMeta.rowData[1] === false && tableMeta.rowData[2] === true) {
						return (
							<StyleTypography
								color={"#0360FD"}
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
							>
								Success
							</StyleTypography>
						);
					} else if (tableMeta.rowData[1] === true && tableMeta.rowData[2] === false) {
						return (
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
								color={"red"}
							>
								Error
							</StyleTypography>
						)
					} else {
						return (
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								fontWeight={"bold"}
								color={"#707070"}
							>
								Processing
							</StyleTypography>
						)
					}
				}
			}
		},
		{
			name: "id",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => {
					return (
						<Grid
							className={"column-style"}
						>
							<IconButton
								onClick={() =>
									downloadEPS({
										eps_submissions: {
											eps_id: value,
										}
									})
								}
							>
								<img
									className={"img-style"}
									src={DownloadIcon}
									alt={"Download"}
								/>
							</IconButton>
						</Grid>
					);
				},
			},
		},
	];

	useEffect(() => {
		findEmployer(['tax_year']);
		me();
		getEpsReport();
		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [findEmployer, getEpsReport, me]);

	if (user_loading || report_loading || employer_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"reports-eps"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
				employer={employer}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Reports"}
						activeSubpage={""}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
						className={"reports-eps-item"}
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
							justifyContent={"space-between"}
							alignItems={"center"}
						>
							<StyleTypography
								fontSize={40}
								fontSizeMedium={30}
								fontWeight={"bold"}
							>
								Employer Payment Summary (EPS)
							</StyleTypography>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#ABAEB7"}
							>
								Monthly reports with the employer payment summary which contain values that <br/>
								can’t be included in the full payment submission (FPS).
							</StyleTypography>
							<Grid
								className={"reports-eps-divider"}
							>
								<Divider/>
							</Grid>
							<Grid
								xs={3}
								item
								marginBottom={3}
							>
								<StyleButton
									onClick={(event) => {
										setAnchorEl(event.currentTarget);
										setPopOver(true);
									}}
								>
									New EPS Submission
								</StyleButton>
								<Menu
									className={'team-member-menu'}
									open={popOver}
									anchorEl={anchorEl}
									onClose={() => {
										setPopOver(false);
									}}
								>
									<MenuItem
										className={'team-member-menu-item'}
										onClick={() => {
											setOpenEpsModal(true);
											setAnchorEl(null);
											setPopOver(false);
											setEpsMode('no_payment')
										}}
									>
										<StyleTypography
											fontSize={15}
											fontSizeMedium={13}
											color={'#000'}
										>
											+ No payment submission
										</StyleTypography>
									</MenuItem>
									<MenuItem
										className={'team-member-menu-item'}
										onClick={() => {
											setAnchorEl(null);
											setPopOver(false);
										}}
										disabled
									>
										<StyleTypography
											fontSize={15}
											fontSizeMedium={13}
											color={'#000'}
										>
											+ Bank details submission
										</StyleTypography>
									</MenuItem>
								</Menu>
							</Grid>
							{
								eps_reports.length > 0 &&
								<Grid className={"reports-eps-year-download"}>
									{
										eps_reports?.map(tax_year => {
											return (
												<Grid key={tax_year.id}>
													<Accordion
														className={"reports-eps-accordion-style"}
													>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-controls={"panel1bh-content"}
														>
															<Grid container direction={"row"} alignItems={"center"}>
																<Grid item>
																	<StyleTypography
																		fontSize={20}
																		fontSizeMedium={14}
																		fontWeight={"bold"}
																		color={"black"}
																	>
																		Tax year: {tax_year.formated_tax_year}
																	</StyleTypography>
																</Grid>
															</Grid>
														</AccordionSummary>
														<AccordionDetails>
															{
																tax_year?.employer_payment_summaries?.map(eps_report =>  (
																	<Grid
																		container
																		direction={"row"}
																		alignItems={"center"}
																		key={eps_report.id}
																		marginBottom={"0.8vw"}
																	>
																		<Grid
																			item
																			marginRight={"4.4%"}
																			className={"reports-eps-accordion-content-style"}
																			onClick={() => {
																				setTitle(
																					"Tax Month: " + eps_report.tax_month
																				);
																				setEpsDownloadArray(eps_report?.eps_submissions);
																				setEpsId(eps_report?.id);
																				setTaxMonthFigures(eps_report);
																				setOpen(true);
																			}}
																		>
																			<StyleTypography
																				fontSize={15}
																				fontSizeMedium={11}
																				color={"#919196"}
																			>
																				Tax month: {eps_report.tax_month}
																			</StyleTypography>
																		</Grid>
																	</Grid>
																))
															}
														</AccordionDetails>
													</Accordion>
												</Grid>
											)
										})
									}
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<DownloadReportDialog
				title={title}
				report_array={epsDownloadArray}
				columns={columns}
				open={open}
				setOpen={setOpen}
				isEps={true}
				submitEPS={submitEPS}
				epsId={epsId}
				downloadEPS={downloadEPS}
				fpsDownloadArray={[]}
				epsDownloadArray={epsDownloadArray}
				taxMonthFigures={taxMonthFigures}
			/>
			<Dialog
				fullWidth={true}
				maxWidth={'sm'}
				open={openEpsModal}
				onClose={() => {
					setOpenEpsModal(false);
					setNoPaymentPeriod(0);
					setEpsMode('');
				}}
			>
				<DialogContent>
					<StyleTypography
						fontSize={28}
						fontSizeMedium={24}
						color={'#0160FD'}
						fontWeight={'bold'}
					>
						CONFIRM EPS SUBMISSION
					</StyleTypography>

					<Grid
						marginTop={3}
						display={epsMode === 'no_payment' ? 'grid' : 'none'}
					>
						<Select
							className={'default-select details-company-select'}
							fullWidth
							required
							value={noPaymentPeriod}
							onChange={(e) => setNoPaymentPeriod(e.target.value)}
						>
							<MenuItem
								key={0}
								value={0}
								disabled
							>
								Select no payment period
							</MenuItem>

							{
								Object.entries(taxMonths).map(([key, value]) => {
									return (
										<MenuItem
											key={key}
											value={key}
										>
											{value}
										</MenuItem>
									);
								})
							}
						</Select>
					</Grid>

					<Grid
						marginTop={2}
					>
						<StyleTypography
							fontSize={22}
							fontSizeMedium={16}
							color={'#000000'}
						>
							Do you confirm that you want to submit EPS ?
							<ul>
								<li>Tax Year: {employer?.tax_year?.formated_tax_year}</li>
								{
									epsMode === 'no_payment' && (
										<>
											<li>No Payment submission</li>
											<li>Period: {taxMonths[noPaymentPeriod]}</li>
										</>
									)
								}
							</ul>
							Once you click on confirm, we will send automatically the required data to HMRC for RTI
							purposes.
						</StyleTypography>
					</Grid>
				</DialogContent>
				<DialogActions>
					<StyleButton
						fullWidth={false}
						onClick={() => {
							setOpenEpsModal(false);
							setEpsMode('');
							setNoPaymentPeriod(0);
						}}
						isWhiteButton={true}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#000000"}
						>
							CANCEL
						</StyleTypography>
					</StyleButton>
					<StyleButton
						disabled={
							(epsMode === 'no_payment' && noPaymentPeriod === 0)
						}
						fullWidth={false}
						onClick={() => {
							submitEPS({
								pay_schedule_runs: {
									eps_mode: epsMode,
									no_payment_period: noPaymentPeriod
								}
							});
							setEpsMode('');
							setNoPaymentPeriod(0);
							setOpenEpsModal(false);
						}}
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={16}
							fontWeight={"bold"}
							color={"#FFFFFF"}
						>
							CONFIRM
						</StyleTypography>
					</StyleButton>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		Reports,
		Employer
	} = state;

	return {
		...Profile,
		...Reports,
		...Employer
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	findEmployer: (relations = []) => dispatch(findEmployer(relations)),
	getEpsReport: () => dispatch(getEpsReport()),
	downloadEPS: (data) => dispatch(downloadEPS(data)),
	submitEPS: (data) => dispatch(submitEPS(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsEps)