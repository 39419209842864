import { Button, Grid } from "@mui/material";
import { connect } from "react-redux";
import { StyleAppBar } from "../../components/StyleAppBar";
import { me } from "../../actions/Profile";
import React, { useEffect } from "react";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import CheckIcon from '../../images/svg/check.svg'
import { useSearchParams } from "react-router-dom";
import { downloadPayInstructions } from "../../actions/PayrollJournal";
import { useNavigate } from "react-router-dom";
import {viewPaymentBreakdown} from "../../actions/PayScheduleRun";

const PayrollActivePage = ({
    user,
    me,
    viewPaymentBreakdown
}): JSX.Element => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        me()
    }, [me])

    return (
        <Grid container justifyContent={"center"}>
            <StyleAppBar
                showBackButton={false}
                user={user}
            />
            <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                container
                id={"main-content-container"}
            >
                <Grid
                    xl={true}
                    lg={true}
                    md={true}
                    sm={true}
                    xs={true}
                    item
                    container
                    direction={"row"}
                    alignContent={"flex-start"}
                    sx={{
                        flex: 1,
                    }}
                >
                    <Grid
                        container
                    >
                        <Grid
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item
                            className={"completed-payroll-container"}
                        >
                            <div className={"check-icon-container"}>
                                <img src={CheckIcon} alt="check" className={"check-icon"} />
                                <StyleTypography fontWeight="bold" className="completed-payroll-title">
                                    Payrun completed
                                </StyleTypography>
                            </div>
                            <div className={"completed-payroll-text"}>
                                <ul className={"completed-payroll-list"}>
                                    <li>
                                        <span className={"completed-payroll-list-item"}>
                                            Pay Schedule: {searchParams.get("pay_schedule")}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={"completed-payroll-list-item"}>
                                            Tax Period: {searchParams.get("tax_period")}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={"completed-payroll-list-item"}>
                                            Pay Date: {searchParams.get("pay_date")}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className={"what-is-happening-container"}>
                                <div className={"what-is-happening"}>
                                    <p className={"what-is-happening-title"}>We are now sending:</p>
                                    <ul className={"completed-payroll-list-small"}>
                                        <li>
                                            <span className={"completed-payroll-list-item-small"}>
                                                Your Full Payment Submission (FPS) to HMRC;
                                            </span>
                                        </li>
                                        <li>
                                            <span className={"completed-payroll-list-item-small"}>
                                                Payslips to workers
                                            </span>
                                        </li>
                                    </ul>
                                    <p className={"what-is-happening-text"}>
                                        We submit your Employer Payment Submission (EPS) to HMRC every 8th day of each month.
                                    </p>
                                </div>
                                <p className={"what-is-happening-text"}>
                                    <b style={{ color: 'black' }}>Reminder:</b> Don't forget pay your employees their net wages.
                                </p>
                                <p
                                    className={"download-link"}
                                    onClick={() => {
                                        viewPaymentBreakdown({
                                            "pay_schedule_runs": {
                                                id: searchParams.get("id"),
                                            }
                                        },{}, true);
                                    }}
                                >
                                    Click here to download net pay instructions.
                                </p>
                            </div>
                            <Button
                                fullWidth
                                className={"default-black-button completed-payroll-button"}
                                onClick={() => navigate("/main")}
                            >
                                Brilliant!
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}


const mapStateToProps = state => {
    const {
        Profile,
        PayScheduleRun,
    } = state;

    return {
        ...Profile,
        ...PayScheduleRun,
    }
}


const mapDispatchToProps = dispatch => ({
    me: () => dispatch(me()),
    downloadPayInstructions: (data) => dispatch(downloadPayInstructions(data)),
    viewPaymentBreakdown: (id, openModal, isDownload) => dispatch(viewPaymentBreakdown(id,openModal, isDownload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollActivePage)