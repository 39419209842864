import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {me} from "../../actions/Profile";
import {LeftMenu} from "../../components/LeftMenu";
import {StyleAppBar} from "../../components/StyleAppBar";
import StyleTypography from "../../components/StyledComponents/StyleTypography";
import Loading from "../../components/Loading";
import {Avatar, Drawer, InputAdornment, Tab, Tabs} from "@mui/material";
import EmployeeSummary from "../../components/Pages/RunPayroll/EmployeeSummary";
import {useParams} from "react-router-dom";
import {sendPayslip, viewPaymentBreakdown, viewPayslip, viewSubmittedPayrun} from "../../actions/PayScheduleRun";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import ReviewPayroll from "../../components/Pages/RunPayroll/ReviewPayroll";
import StyleTable from "../../components/StyledComponents/StyleTable";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ViewPayroll = ({
	me = () => {},
	user = {},
	pay_schedule_run_loading = false,
	employee_pay_runs = [{}],
	user_loading = false,
	viewSubmittedPayrun = () => {},
	payroll_summary = {},
	viewPayslip = () => {},
	sendPayslip = () => {},
	viewPaymentBreakdown = () => {},
	payments_breakdown = [],
	table_loading = false,
}): JSX.Element => {

	const id = useParams().payRunId;

	const [showSummary, setShowSummary] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState({});
	const [tabIndex, setTabIndex] = useState(0);

	//Table variables
	const [searchText, setSearchText] = useState("");


	const columns = [
		{
			name: "employee",
			label: "Employee",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value) => (
					<Grid
						container
						alignItems={"center"}
					>
						<Grid
							sx={{
								marginRight: "10px",
							}}
						>
							<Avatar
								sx={{
									width: "2.78vw",
									height: "2.78vw",
								}}
							>
								<StyleTypography
									fontSize={18}
									fontSizeMedium={13}
									color={"#FFFFFF"}
									fontWeight={'bold'}
								>
									{value?.forename[0]} {value?.surname[0]}
								</StyleTypography>
							</Avatar>
						</Grid>
						<Grid>
							<StyleTypography
								fontSize={18}
								fontSizeMedium={13}
								color={"#000000"}
								fontWeight={'bold'}
							>
								{value?.forename} {value?.surname}
							</StyleTypography>
							{
								value?.on_leave && (
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
									>
										On Leave
									</StyleTypography>
								)
							}
							{
								value?.first_payroll_run && (
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
									>
										First time payroll
									</StyleTypography>
								)
							}
							{
								value?.status === 'Terminated' && (
									<StyleTypography
										fontSize={16}
										fontSizeMedium={12}
										color={"red"}
									>
										Termination
									</StyleTypography>
								)
							}
						</Grid>
					</Grid>
				)
			},
		},
		{
			name: "gross_income_for_tax",
			label: "Gross Pay",
			options: {
				filter: true,
				sort: false,
				display: tabIndex !== 1,
				setCellProps: () => ({className: "cell-style"}),
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{
							parseFloat(value ?? 0).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
						}   )}
					</StyleTypography>
				)
			}
		},
		{
			name: "net_pay",
			label: "Net Pay",
			options: {
				filter: true,
				sort: false,
				display: tabIndex !== 1,
				setCellProps: () => ({className: "cell-style"}),
				customBodyRender: (value) => (
					<StyleTypography
						fontSize={18}
						fontSizeMedium={13}
						color={"#000000"}
						fontWeight={'bold'}
					>
						{
							parseFloat(value ?? 0).toLocaleString('en-GB', {
								style: 'currency',
								currency: 'GBP',
							}   )}
					</StyleTypography>
				)
			}
		},
	];

	useEffect(() => {
		if (!user.id) {
			me();
		}

		viewSubmittedPayrun({
			"pay_schedule_runs": {
				id: id,
			}
		});

		document.documentElement.style.setProperty('--scroll-height', `${document.body.scrollHeight}`);
	}, [me, user, viewSubmittedPayrun, id]);

	if (pay_schedule_run_loading || user_loading) {
		return <Loading/>;
	}

	return (
		<Grid
			container
			justifyContent={"center"}
			id={"run-payroll-page"}
		>
			<StyleAppBar
				showBackButton={false}
				user={user}
			/>
			<Grid
				xl={12}
				lg={12}
				md={12}
				sm={12}
				xs={12}
				item
				container
				id={"main-content-container"}
			>
				<Grid>
					<LeftMenu
						activePage={"Payroll"}
						expanded={!showSummary}
					/>
				</Grid>
				<Grid
					xl={true}
					lg={true}
					md={true}
					sm={true}
					xs={true}
					item
					container
					direction={"row"}
					alignContent={"flex-start"}
					sx={{
						flex: 1,
						marginLeft: "36px",
					}}
				>
					<Grid
						container
					>
						<Grid
							xl={12}
							lg={12}
							md={12}
							sm={12}
							xs={12}
							item
						>
							<Tabs
								className={"tabs-run-payroll"}
								value={tabIndex}
								onChange={(event, newValue) => {
									setTabIndex(newValue);
								}}
							>
								<Tab
									sx={{borderBottom: "5px solid transparent"}}
									label={"Summary"}
									value={0}
								/>
								<Tab
									sx={{borderBottom: "5px solid transparent"}}
									label={"Employees"}
									value={1}
								/>
							</Tabs>
						</Grid>

						{
							tabIndex === 1 ? (
								<Grid
									xl={12}
									lg={12}
									md={12}
									sm={12}
									xs={12}
									item
									container
									className={"team-member-table-container"}
									justifyContent={"space-between"}
								>
									<Grid
										item
										xs
									>
										<Grid
											container
											xl={12}
											lg={12}
											md={12}
											sm={12}
											xs={12}
											item
										>
											<TextField
												className={"default-text-field"}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<SearchIcon sx={{fontSize: '1.25vw'}}/>
														</InputAdornment>
													),
													placeholder: "Search employee",
												}}
												fullWidth
												onChange={(e) => {
													setSearchText(e.target.value);
												}}
											/>
										</Grid>
										<StyleTable
											columns={columns}
											data={employee_pay_runs?.data ?? []}
											options={{
												selectableRowsHeader: true,
												enableNestedDataAccess: ".",
												selectToolbarPlacement: "none",
												serverSide: true,
												textLabels: {
													body: {
														noMatch: table_loading ?
															<Grid
																marginTop={30}
															>
																<Loading/>
															</Grid>
															:
															'Sorry, there is no matching data to display',
													},
												},
												rowsPerPage: employee_pay_runs?.per_page,
												count: employee_pay_runs.total,
												searchText: searchText,

												onTableChange: (action, tableFilters) => {
													switch (action) {
														case "changePage":
															setShowSummary(false);
															viewSubmittedPayrun({
																"pay_schedule_runs": {
																	id: id,
																	search: searchText,
																	page: tableFilters.page + 1,
																	per_page: tableFilters.rowsPerPage
																}
															}, true);
															break;
														case "changeRowsPerPage":
															setShowSummary(false);
															viewSubmittedPayrun({
																"pay_schedule_runs": {
																	id: id,
																	search: searchText,
																	page: tableFilters.page,
																	per_page: tableFilters.rowsPerPage
																}
															}, true);
															break;
														default:
															break;
													}
												},

												onRowClick: (rowData, rowMeta) => {
													setSelectedEmployee(employee_pay_runs?.data[rowMeta.dataIndex]);
													setShowSummary(!showSummary);
												},
												setRowProps: () => {
													return {
														style: { cursor: 'pointer' },
													};
												},
											}}
											removeToolbar={true}
										/>
									</Grid>
								</Grid>
							) : (
								<ReviewPayroll
									payroll_summary={payroll_summary}
									showButton={false}
									viewPaymentBreakdown={viewPaymentBreakdown}
									payments_breakdown={payments_breakdown}
									payroll_id={id}
								/>
							)
						}
					</Grid>
				</Grid>

				{/*Employee summary*/}
				{
					showSummary && (
						<Grid
							item
							xl={3}
							lg={3}
							xs={3}
							md={3}
							sm={3}
						>
							<Drawer
								open={true}
								variant="persistent"
								anchor="right"
								sx={{
									width: '24.5313vw',
									flexShrink: 0,
									'& .MuiDrawer-paper': {
										width: '24.5313vw',
									},
								}}
							>
								<EmployeeSummary
									selectedEmployee={selectedEmployee}
									setSelectedEmployee={setSelectedEmployee}
									payrollSubmitted={true}
									viewPayslip={viewPayslip}
									sendPayslip={sendPayslip}
								/>
							</Drawer>
						</Grid>
					)
				}
			</Grid>
		</Grid>
	);
}

const mapStateToProps = state => {
	const {
		Profile,
		PayScheduleRun,
	} = state;

	return {
		...Profile,
		...PayScheduleRun,
	}
}

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	viewSubmittedPayrun: (data, isTableFilter) => dispatch(viewSubmittedPayrun(data, isTableFilter)),
	viewPayslip: (data) => dispatch(viewPayslip(data)),
	sendPayslip: (data) => dispatch(sendPayslip(data)),
	viewPaymentBreakdown: (id, openModal) => dispatch(viewPaymentBreakdown(id,openModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPayroll)