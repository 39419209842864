import Grid from "@mui/material/Grid";

import StyleTypography from "../../../StyledComponents/StyleTypography";

import {Schedule, SignalCellularAlt} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import React, {useState} from "react";
import Actions from "../../../../images/svg/ActionsVertical.svg";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";


const SalaryItem = ({
	salary_item = {
		salary_multiplier: null,
		salary_period: "",
		salary_rate: "",
		salary_type: "",
		name: "",
	},
	last = false,
	removeSalaryItem = () => {}
}): JSX.Element => {

	const [anchorEl, setAnchorEl] = useState(null);
	const [popOver, setPopOver] = useState(false);

	return (
		<Grid>
			<Menu
				className={'team-member-menu'}
				open={popOver}
				anchorEl={anchorEl}
				onClose={() => {
					setPopOver(false);
				}}
			>
				<MenuItem
					className={'team-member-menu-item'}
					onClick={() => {
						setAnchorEl(null);
						setPopOver(false);
						removeSalaryItem(salary_item.id)
					}}
				>
					<StyleTypography
						fontSize={15}
						fontSizeMedium={13}
						color={'#000'}
					>
						Remove rate
					</StyleTypography>
				</MenuItem>
			</Menu>
			<Grid
				container
				justifyContent={"space-between"}
				direction={"row"}
				alignItems={"center"}
				className={"salary-item"}
			>
				<Grid
					item
					xs={2}
					sm={1.5}
					md={.8}
					lg={1}
					xl={1}
				>
					{(salary_item.salary_period === "Hour" ||  salary_item.salary_period === null) && <Schedule/>}
					{salary_item.salary_period === "Unit" && <SignalCellularAlt/>}
				</Grid>

				<Grid
					container
					item
					xs={5}
					sm={5}
					md={5}
					lg={5}
					xl={5}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						<StyleTypography
							color={"#000"}
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
						>
							{salary_item.name}
						</StyleTypography>

						<StyleTypography
							color={"#ABAEB7"}
							fontSize={16}
							fontSizeMedium={12}
						>
							{
								(salary_item.salary_period === "Hour" ||  salary_item.salary_period === null) ? "Hours" : "Units"
							}
						</StyleTypography>
					</Grid>
				</Grid>

				<Grid
					container
					item
					xs={5}
					sm={5}
					md={5}
					lg={5}
					xl={5}
					justifyContent={"flex-end"}
				>
					<Grid
						item
					>
						<StyleTypography
							color={"#000"}
							fontSize={18}
							fontSizeMedium={13}
							fontWeight={"bold"}
							textAlign={"end"}
						>
							{salary_item.salary_type === "Overtime" && `${salary_item.salary_multiplier}x`}
							{salary_item.salary_type === "Salary" && `£ ${salary_item.salary_rate}`}
						</StyleTypography>

						<StyleTypography
							color={"#ABAEB7"}
							fontSize={16}
							fontSizeMedium={13}
							textAlign={"end"}
						>
							Rate per {
								(salary_item.salary_period === "Hour" ||  salary_item.salary_period === null) ? "Hour" : "Unit"
							}
						</StyleTypography>
					</Grid>

					<Grid
						item
						marginLeft={1}
					>
						<IconButton
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setPopOver(true);
							}}
						>
							<img
								src={Actions}
								alt={'actions'}
								id={'img-actions-button'}
							/>
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
			{
				!last &&
					<Grid
						margin={"0 auto"}
						width={"95%"}
					>
							<Divider className={"divider-style"}/>
					</Grid>
			}
		</Grid>
	);
}

export default SalaryItem;