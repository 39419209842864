import { Grid } from "@mui/material";
import { StyleAppBar } from "../StyleAppBar";
import { LeftMenu } from "../LeftMenu";

export default function Layout({
  user,
  children,
  backLink,
  showBackButton,
  activePage,
  leftMenuFixed = false,
  expanded = true,
  activeSubpage = "",
  isOnboarding = false,
  lockMenuItems = [],
}) {
  return (
    <Grid container id={"default-layout"}>
      <StyleAppBar
        showBackButton={showBackButton}
        backLink={backLink}
        user={user}
      />

      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        item
        container
        id={"default-content-container"}
      >
        <Grid xl={3} lg={3} md={3} sm={3} xs={3} item>
          <LeftMenu
            activePage={activePage}
            isFixed={leftMenuFixed}
            expanded={expanded}
            activeSubpage={activeSubpage}
            isOnboarding={isOnboarding}
            lockMenuItems={lockMenuItems}
          />
        </Grid>

        <Grid
          xl={true}
          lg={true}
          md={true}
          sm={true}
          xs={true}
          item
          container
          direction={"row"}
          alignContent={"flex-start"}
          sx={{ flex: 1 }}
        >
          <Grid container className={"content-container"}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
