import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import StyleTypography from "./StyledComponents/StyleTypography";
import FullLogoBlack from "../images/svg/Logos/new-fp-logo-allblack-horizontal.svg";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Header = (): JSX.Element => {
	return (
		<Grid
			id="header"
			container
			direction="row"
			alignItems="center"
			justifyContent="space-between"
		>
			<Grid item>
				<img src={FullLogoBlack} alt="FreePayroll"/>
			</Grid>
			<Grid item>
				<Grid container>
					<Grid>
						<StyleTypography
							fontWeight="400"
							fontSize={20}
							fontSizeMedium={15}
							color={"black"}
						>
							New to Collegia FreePayroll?
						</StyleTypography>
					</Grid>
					<Link
						underline="none"
						marginLeft={"5px"}
						className={"link-pointer"}
						href="/sign-up"
					>
						<StyleTypography
							fontSize={20}
							fontSizeMedium={15}
							fontWeight={"bold"}
						>
							Sign up
						</StyleTypography>
					</Link>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Header;
