import Grid from "@mui/material/Grid";
import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle, InputAdornment,
	InputLabel, MenuItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import StyleTypography from "../../../StyledComponents/StyleTypography";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import {CurrencyInputCustom} from "../../../StyledComponents/CurrencyInputCustom";
import React from "react";

const checkDisabledButton = (salary) => {
	switch (salary.type) {
		case "Overtime":
			return (
				parseFloat(salary.multiplier) === "" ||
				!salary.multiplier
			)

		case "Salary":
			return (
				!salary.period ||
				parseFloat(salary.rate) === 0 ||
				!salary.rate
			)
		default: return true;
	}
}

export const CompanySalaryModal = ({
	open,
	setOpen,
	setSalary,
	createSalaryItem,
	salary,
	employer_loading,
}): JSX.Element => {

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			id={"salary-dialog"}
		>
			<DialogTitle>
				<StyleTypography
					fontSize={35}
					fontSizeMedium={25}
					fontWeight={"bold"}
				>
					Add Salary
				</StyleTypography>
				<StyleTypography
					fontSize={16}
					fontSizeMedium={11}
					color={"#AAAEB7"}
				>
					Give the salary description and
					choose if it should be calculated
					per hour or per unit.
				</StyleTypography>
			</DialogTitle>
			<DialogContent>
				<Grid
					id={"salary-form-container"}
				>
					<InputLabel
						className={"default-input-label"}
					>
						Salary Type
					</InputLabel>
					<Select
						className={"default-select"}
						fullWidth
						placeholder={"Select Salary Type"}
						required
						value={salary.type}
						onChange={(e) => setSalary({
							type: e.target.value,
							description: "",
							pensionable: true,
							multiplier: null,
							period: "",
							rate: null,
						})}
					>
						<MenuItem
							value={"Overtime"}
						>
							Overtime
						</MenuItem>

						<MenuItem
							value={"Salary"}
						>
							Salary
						</MenuItem>
					</Select>

					<InputLabel
						className={"default-input-label"}
						sx={{marginTop: "10px"}}
					>
						Salary item code (This code will be the code displayed on your journal)
					</InputLabel>
					<FormControl
						fullWidth
					>
						<TextField
							className={"default-text-field"}
							fullWidth
							required
							value={salary.code}
							onChange={(e) => {
								const value = e.target.value;
								if (/^\d*$/.test(value)) {
									setSalary({
										code: value
									});
								}
							}}
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						/>
					</FormControl>

					<InputLabel
						className={"default-input-label"}
						sx={{marginTop: "10px"}}
					>
						Description
					</InputLabel>
					<FormControl
						fullWidth
					>
						<TextField
							className={"default-text-field company-salary-modal-text-field"}
							fullWidth
							onChange={(e) => setSalary({
								description: e.target.value
							})}
							value={salary.description}
						/>
					</FormControl>

					<Grid
						id={"pensionable-checkbox-container"}
					>
						<FormControlLabel
							id={"pensionable-checkbox"}
							control={
								<Checkbox
									onChange={(e) => {
										setSalary({
											pensionable: e.target.checked
										})
									}}
									icon={<CheckBoxOutlineBlankSharpIcon/>}
									checkedIcon={<CheckBoxSharpIcon/>}
									checked={salary.pensionable}
								/>
							}
							label={
							<Grid container direction={"column"}>
								<Grid>
									<StyleTypography
										fontSize={16}
										fontSizeMedium={11}
										color={"#000"}
										fontWeight={"500"}
									>
										Pensionable
									</StyleTypography>
								</Grid>
								<Grid>
									<StyleTypography
										fontSize={14}
										fontSizeMedium={10}
										color={"#AAAEB7"}
									>
										We will include this amount in the
										total sum used to calculate pension contributions.
									</StyleTypography>
								</Grid>

							</Grid>
							}
						/>
					</Grid>

					{
						salary.type === "Overtime" &&
						<Grid>
							<InputLabel
								className={"default-input-label"}
							>
								Multiply by (Eg: 1.5)
							</InputLabel>

							<TextField
								className={"default-text-field company-salary-modal-text-field"}
								InputProps={{
									startAdornment: <InputAdornment position={"start"}>
										<StyleTypography fontSize={16} fontSizeMedium={16} color={"black"} fontWeight={"bold"}>
											X
										</StyleTypography>
									</InputAdornment>,
									inputComponent: CurrencyInputCustom,
									inputProps: {prefix: ""},
								}}
								value={salary.multiplier ?? ""}
								onChange={(e) => {
									setSalary({
										multiplier: e.target.value
									})
								}}
								error={parseFloat(salary.multiplier) === 0}
								helperText={parseFloat(salary.multiplier) === 0 && "Multiplier cannot be 0."}
							/>
						</Grid>
					}

					{
						salary.type === "Salary" &&
						<Grid>
							<InputLabel
								className={"default-input-label"}
							>
								Salary Period
							</InputLabel>
							<Select
								className={"default-select"}
								fullWidth
								placeholder={"Select Salary Period"}
								required
								value={salary.period}
								onChange={(e) => setSalary({
									period: e.target.value
								})}
							>
								<MenuItem
									value={"Hour"}
								>
									Hour
								</MenuItem>

								<MenuItem
									value={"Unit"}
								>
									Unit
								</MenuItem>
							</Select>

							<InputLabel
								className={"default-input-label"}
							>
								Salary Rate
							</InputLabel>

							<TextField
								className={"default-text-field company-salary-modal-text-field"}
								InputProps={{
									startAdornment: <InputAdornment position={"start"}>
										<StyleTypography
											fontSize={16}
											color={"black"}
											fontWeight={"bold"}
										>
											£
										</StyleTypography>
									</InputAdornment>,
									inputComponent: CurrencyInputCustom,
									inputProps: {prefix: ""},
								}}
								value={salary.rate ?? ""}
								onChange={(e) => {
									setSalary({
										rate: e.target.value
									})
								}}
								error={parseFloat(salary.rate) === 0}
								helperText={parseFloat(salary.rate) === 0 && "Salary rate cannot be 0."}
							/>
						</Grid>
					}
				</Grid>

				<Grid
					container
					spacing={2}
					id={"salary-buttons-container"}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={4}
						xl={4}
					>
						<Button
							className={"default-black-button"}
							disabled={
								!salary.type ||
								!salary.description ||
								checkDisabledButton(salary) ||
								employer_loading ||
								!salary.code
							}
							onClick={() => createSalaryItem({
								employers: {
									salary
								}
							})}
						>
							{employer_loading ? <CircularProgress/> : "Add Salary"}
						</Button>
					</Grid>

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={4}
						xl={4}
					>
						<Button
							className={"default-white-button"}
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>

			</DialogContent>
		</Dialog>
	)
}
